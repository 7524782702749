import { ENDPOINT } from "@/constants/endpoints";
import { ROUTE } from "@/constants/routes";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { keyFactory } from "../keyFactory";
import { NestError } from "../nest-error";
import { ExamOptionsDto } from "./dto/exam-options.dto";

// create

const createRegistration = async (candidateId?: number) =>
  axios
    .put<ExamOptionsDto>(ENDPOINT.draft.putCreate(candidateId))
    .then((res) => res.data);

export const useMutationDraftCreate = (
  props?: Partial<
    UseMutationOptions<ExamOptionsDto, AxiosError, number | undefined>
  >
) =>
  useMutation({
    mutationFn: createRegistration,
    ...props,
  });

// get

const getOptions = async (candidateId?: number) =>
  axios
    .get<ExamOptionsDto>(ENDPOINT.draft.getOptions(candidateId))
    .then((res) => res.data);

export const useQueryDraftOptions = (
  candidateId?: number,
  props?: Partial<UseQueryOptions<ExamOptionsDto, AxiosError<NestError>>>
) =>
  useQuery({
    queryKey: keyFactory.draft.options(candidateId),
    queryFn: () => getOptions(candidateId),
    staleTime: Infinity,
    gcTime: Infinity,
    ...props,
  });

// set

interface SetOptionsProps {
  candidateId?: number;
  options: ExamOptionsDto;
}

const setOptions = async ({ options, candidateId }: SetOptionsProps) => {
  await axios.patch<void>(ENDPOINT.draft.patchOptions(candidateId), options);
};

export const useMutationDraftSetOptions = (
  props?: Partial<UseMutationOptions<void, AxiosError, SetOptionsProps>>
) =>
  useMutation({
    mutationFn: setOptions,
    ...props,
  });

// confirm

const confirmRegistration = async (candidateId?: number) => {
  await axios.post<void>(ENDPOINT.draft.postConfirm(candidateId), {
    companyCreationUrl: ROUTE.company.accountCreation(),
  });
};

export const useMutationDraftConfirm = (
  props?: Partial<
    UseMutationOptions<void, AxiosError<NestError>, number | undefined>
  >
) =>
  useMutation({
    mutationFn: confirmRegistration,
    ...props,
  });
