import { Info } from "lucide-react";

const WarningMessage = () => {
  return (
    <div className="flex flex-row p-4 items-start self-stretch gap-4 border rounded-md border-gray-300 bg-white shadow">
      <Info className="w-5 h-5 flex-shrink-0" />
      <div className="flex flex-col">
        <span className="font-bold">Attention</span>
        <span className="text-gray-600 font-normal">
          Votre choix de mode(s) de transport et de choix de la ou les classe(s)
          de marchandises dangereuses correspond à votre périmètre d'examen et
          de certificat. Nous vous invitons donc à bien vérifier l'adéquation de
          votre périmètre avec celui de votre ou vos formation(s), ainsi que
          celui de votre activité professionnelle.
        </span>
        <span className="text-brand-700 font-semibold">
          Si la date de clôture des inscriptions est dépassée, un changement ne
          sera plus possible.
        </span>
      </div>
    </div>
  );
};

export default WarningMessage;
