import { ExamStatus, ExamStatusUtil } from "@/api/enums/exam-status.enum";
import { ExamDetailedDtoClient } from "@/api/exam/dto/exam-detailed.dto";
import { Button } from "@/components/atoms/Button";
import ArchiveExamDialog from "@/components/molecules/ArchiveExamDialog";
import { ROUTE } from "@/constants/routes";
import { useNavigate } from "react-router-dom";
import CancelQuestionDialog from "./CancelQuestionDialog";
import GenerateCertificatesDialog from "./GenerateCertificatesDialog";
import GenerateNotesDialog from "./GenerateNotesDialog";
import { useState } from "react";
import { GraduationCap } from "lucide-react";

interface SessionButtonsProps {
  exam: ExamDetailedDtoClient;
}

const SessionButtons = ({ exam }: SessionButtonsProps) => {
  const navigate = useNavigate();
  const [certificatesDialogOpen, setCertificatesDialogOpen] = useState(false);

  return (
    <div className="flex flex-row gap-2 items-center flex-wrap p-2 w-full justify-end">
      {ExamStatusUtil.order(exam.status) >=
        ExamStatusUtil.order(ExamStatus.QCMS_GENERATED) && (
        <Button
          variant="ghostWithBorders"
          className="text-gray-800 font-semibold w-fit"
          onClick={() => navigate(ROUTE.admin.dashboard.live.session(exam.id))}
        >
          Accéder à la session en temps réel
        </Button>
      )}

      {ExamStatusUtil.order(exam.status) >=
        ExamStatusUtil.order(ExamStatus.PASSED) && (
        <CancelQuestionDialog examId={exam.id} />
      )}

      {ExamStatusUtil.order(exam.status) >=
        ExamStatusUtil.order(ExamStatus.PASSED) && (
        <GenerateNotesDialog examId={exam.id} />
      )}

      {ExamStatusUtil.order(exam.status) >=
        ExamStatusUtil.order(ExamStatus.NOTES_GENERATED) && (
        <>
          <Button
            className="flex flex-row items-center gap-2"
            onClick={() => setCertificatesDialogOpen(true)}
          >
            <GraduationCap className="rounded-full w-6 h-6 text-white p-0.5" />
            Générer les certificats
          </Button>
          <GenerateCertificatesDialog
            examId={exam.id}
            open={certificatesDialogOpen}
            setOpen={setCertificatesDialogOpen}
          />
        </>
      )}

      <ArchiveExamDialog examId={exam.id} archived={exam.archived} />
    </div>
  );
};

export default SessionButtons;
