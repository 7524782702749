import { useQueryCertificateAll } from "@/api/certificate/certificate";
import { CertificateStatus } from "@/api/enums/CertificateStatus";
import { ExtensionType } from "@/api/enums/ExtensionType";
import { MerchandiseClass } from "@/api/enums/merchandise-class.enum";
import { TransportMode } from "@/api/enums/transport-mode.enum";
import useChildForm from "@/hooks/ChildForm";
import { Perimeter } from "@/lib/perimeter";
import { useEffect, useMemo, useState } from "react";
import {
  MerchandisesForm,
  TransportModesForm,
} from "../../PerimeterForm/PerimeterForm";
import CertificatesSwitches from "../CertificatesSwitches";
import UTCCombobox from "../UTCCombobox";
import ExtensionMessage from "./ExtensionMessage";
import ExtensionTypeButtons from "./ExtensionTypeButtons";

interface ExtensionPropertiesFormProps {
  fieldName?: string;
  candidateId?: number;
}

const ExtensionPropertiesForm = ({
  fieldName,
  candidateId,
}: ExtensionPropertiesFormProps) => {
  const { watch, setValue, transformPath } = useChildForm(fieldName);

  const certificatesQuery = useQueryCertificateAll(candidateId);

  const extensionType = watch(transformPath("extension.extensionType"));
  const extensionCertificateID = watch(transformPath("certificateId"));
  const [extensionMinimumPerimeter, setExtensionMinimumPerimeter] =
    useState<Perimeter>(Perimeter.full());

  const validCertificates = useMemo(
    () =>
      certificatesQuery.data?.filter(
        ({ expirationDate, status }) =>
          new Date(expirationDate) >= new Date() &&
          (status as CertificateStatus) === CertificateStatus.VALIDATED
      ) ?? [],
    [certificatesQuery.data]
  );

  useEffect(() => {
    const newMinimumPerimeter =
      certificatesQuery.data?.find(({ id }) => extensionCertificateID === id)
        ?.perimeter || Perimeter.full();

    if (extensionType === ExtensionType.MERCHANDISES)
      setValue(
        transformPath("extension.extendedMerchandiseClasses"),
        watch(transformPath("extension.extendedMerchandiseClasses")).filter(
          (val: MerchandiseClass) =>
            !newMinimumPerimeter.merchandises.includes(val)
        )
      );
    else
      setValue(
        transformPath("extension.extendedTransportModes"),
        watch(transformPath("extension.extendedTransportModes")).filter(
          (val: TransportMode) =>
            !newMinimumPerimeter.transportModes.includes(val)
        )
      );

    setExtensionMinimumPerimeter(Perimeter.from(newMinimumPerimeter));
  }, [
    extensionType,
    extensionCertificateID,
    setValue,
    watch,
    certificatesQuery.data,
    transformPath,
  ]);

  return (
    <>
      <UTCCombobox fieldName={transformPath("utc")} />
      <CertificatesSwitches
        certificates={validCertificates}
        fieldName={transformPath("certificateId")}
        isArray={false}
        titleElement={
          <>
            <h2>Extension de certificat</h2>
            <ExtensionMessage />
          </>
        }
      />
      <ExtensionTypeButtons
        fieldName={transformPath("extension.extensionType")}
      />
      {extensionType === ExtensionType.MERCHANDISES && (
        <MerchandisesForm
          fieldName={transformPath("extension.extendedMerchandiseClasses")}
          hiddenMerchandises={[
            ...extensionMinimumPerimeter.merchandises,
            MerchandiseClass.HYDROCARBONS,
          ]}
        />
      )}
      {extensionType === ExtensionType.TRANSPORTS && (
        <TransportModesForm
          fieldName={transformPath("extension.extendedTransportModes")}
          hiddenTransportModes={extensionMinimumPerimeter.transportModes}
        />
      )}
    </>
  );
};

export default ExtensionPropertiesForm;
