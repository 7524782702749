import { ConstantKey } from "@/api/constant/constants";
import { ExamType } from "@/api/enums/ExamType";
import { ExamExportDto } from "@/api/export/dto/exam-export.dto";
import { SessionExportDto } from "@/api/export/dto/session-export.dto";
import { DateDto } from "@/api/qcm/dto/date.dto";
import { format } from "date-fns";

const ENDPOINT = {
  helloWorld: "/",
  debugSentry: "/debug-sentry",

  admin: {
    base: "/admin",
    getAll: () => `${ENDPOINT.admin.base}`,
    postAdd: () => `${ENDPOINT.admin.base}`,
    putChangePassword: (id: number) => `${ENDPOINT.admin.base}/${id}`,
    delete: (id: number) => `${ENDPOINT.admin.base}/${id}`,
  },

  auth: {
    base: "/auth",
    getRole: () => `${ENDPOINT.auth.base}/role`,
    postLogin: () => `${ENDPOINT.auth.base}/login`,
    postRefresh: () => `${ENDPOINT.auth.base}/refresh`,
    postLogout: () => `${ENDPOINT.auth.base}/logout`,
    putChangeEmail: (userId?: number) =>
      `${ENDPOINT.auth.base}/email` + (userId ? `/${userId}` : ""),
    register: {
      base: () => `${ENDPOINT.auth.base}/register`,
      postCandidate: () => `${ENDPOINT.auth.register.base()}/candidate`,
      company: {
        base: () => `${ENDPOINT.auth.register.base()}/company`,
        getPrefilledInfo: () =>
          `${ENDPOINT.auth.register.company.base()}/get-prefilled-info`,
        postRegister: () => ENDPOINT.auth.register.company.base(),
      },
    },
    password: {
      base: () => `${ENDPOINT.auth.base}/password`,
      postAskChange: () => `${ENDPOINT.auth.password.base()}/ask-change`,
      postChange: () => `${ENDPOINT.auth.password.base()}/change`,
    },
    postCandidateExamLogin: () => `${ENDPOINT.auth.base}/candidate-exam-login`,
  },

  candidate: {
    base: "/candidate",
    getInfo: (candidateId?: number) =>
      `${ENDPOINT.candidate.base}/info` +
      (candidateId ? `/${candidateId}` : ""),
    postChangeInfo: (candidateId?: number) =>
      `${ENDPOINT.candidate.base}/info` +
      (candidateId ? `/${candidateId}` : ""),
    getRegistrations: (candidateId?: number) =>
      `${ENDPOINT.candidate.base}/registrations` +
      (candidateId ? `/${candidateId}` : ""),
    getCanRegister: () => `${ENDPOINT.candidate.base}/can-register`,
    getAllPaginated: () => `${ENDPOINT.candidate.base}/all`,
    getBasicInfo: (candidateId: number) =>
      `${ENDPOINT.candidate.base}/basic/${candidateId}`,
    postArchive: (candidateId: number) =>
      `${ENDPOINT.candidate.base}/archive/${candidateId}`,
    getOnGoingRegistration: (candidateId: number) =>
      `${ENDPOINT.candidate.base}/ongoing-registration/${candidateId}`,
    getArchivedPaginated: () => `${ENDPOINT.candidate.base}/archived`,
  },

  certificate: {
    base: "/certificate",
    postUpload: () => `${ENDPOINT.certificate.base}/upload`,
    getAll: (candidateId?: number) =>
      `${ENDPOINT.certificate.base}/all` +
      (candidateId ? `/${candidateId}` : ""),
    postProrogationAdd: () => `${ENDPOINT.certificate.base}/prorogation/add`,
    postProrogationRemove: (certificateId: number) =>
      `${ENDPOINT.certificate.base}/prorogation/remove/${certificateId}`,
    getForeignCertificatesPaginated: () =>
      `${ENDPOINT.certificate.base}/foreign-certificates`,
    postForeignCertificatesSetStatus: () =>
      `${ENDPOINT.certificate.base}/foreign-certificates/set-status`,
    postGenerate: () => `${ENDPOINT.certificate.base}/generate`,
  },

  company: {
    base: "/company",
    getInfo: (companyId?: number) =>
      `${ENDPOINT.company.base}/info` + (companyId ? `/${companyId}` : ""),
    postChangeInfo: (companyId?: number) =>
      `${ENDPOINT.company.base}/info` + (companyId ? `/${companyId}` : ""),
    getDelegations: () => `${ENDPOINT.company.base}/delegations`,
    getAllPaginated: () => `${ENDPOINT.company.base}/all`,
  },

  constant: {
    base: () => `/constant`,
    get: (key: ConstantKey) => ENDPOINT.constant.base() + `/${key}`,
    post: (key: ConstantKey) => ENDPOINT.constant.base() + `/${key}`,
  },

  delegation: {
    base: "/delegation",
    postRefuse: () => `${ENDPOINT.delegation.base}/refuse`,
    postNew: () => `${ENDPOINT.delegation.base}/new`,
  },

  draft: {
    base: "/draft",
    putCreate: (candidateId?: number) =>
      `${ENDPOINT.draft.base}${candidateId ? `/${candidateId}` : ""}`,
    getOptions: (candidateId?: number) =>
      `${ENDPOINT.draft.base}${candidateId ? `/${candidateId}` : ""}`,
    patchOptions: (candidateId?: number) =>
      `${ENDPOINT.draft.base}${candidateId ? `/${candidateId}` : ""}`,
    postConfirm: (candidateId?: number) =>
      `${ENDPOINT.draft.base}${candidateId ? `/${candidateId}` : ""}`,
  },

  exam: {
    base: "/exam",
    getAll: () => `${ENDPOINT.exam.base}/`,
    getAllDetailedPaginated: () => `${ENDPOINT.exam.base}/detailed`,
    getDetailed: (examId: number) => `${ENDPOINT.exam.base}/detailed/${examId}`,
    getNextNumber: (type: ExamType) =>
      `${ENDPOINT.exam.base}/next-number/${type}`,
    getRegistrations: () => `${ENDPOINT.exam.base}/registrations`,
    getPerimeters: (examId: number) =>
      `${ENDPOINT.exam.base}/perimeters/${examId}`,
    postCreate: () => `${ENDPOINT.exam.base}/create`,
    postModify: () => `${ENDPOINT.exam.base}/modify`,
    postRemovePreRegistered: () =>
      `${ENDPOINT.exam.base}/remove-pre-registered`,
    postArchive: (examId: number) => `${ENDPOINT.exam.base}/archive/${examId}`,
    getArchived: () => ENDPOINT.exam.base + "/archived",
  },

  examResult: {
    base: "/exam-result",
    postGenerateDocuments: () =>
      `${ENDPOINT.examResult.base}/generate-documents`,
    postCancelQuestion: () => `${ENDPOINT.examResult.base}/cancel-question`,
    postModifyGrade: () => `${ENDPOINT.examResult.base}/modify-grade`,
    getCandidate: (candidateId?: number) =>
      `${ENDPOINT.examResult.base}/candidate/${candidateId ?? ""}`,
    postEdcResults: () => `${ENDPOINT.examResult.base}/edc-results`,
  },

  export: {
    base: "/export",
    getExam: ({ examExport, examId }: ExamExportDto) =>
      `${ENDPOINT.export.base}/exam/${examId}/${examExport}`,
    getSession: ({ date, sessionExport }: SessionExportDto) =>
      `${ENDPOINT.export.base}/session/${date}/${sessionExport}`,
    getAccounting: () => `${ENDPOINT.export.base}/global/accounting`,
    getMinistry: () => `${ENDPOINT.export.base}/global/ministry`,
  },

  files: {
    base: "/files",
    postAskUploadLinks: () => `${ENDPOINT.files.base}/ask-upload-links`,
  },

  formationAttestation: {
    base: "/formation-attestation",
    postNewDocuments: () =>
      `${ENDPOINT.formationAttestation.base}/new-documents`,
    postAllProvided: () => `${ENDPOINT.formationAttestation.base}/all-provided`,
    postStatus: () => `${ENDPOINT.formationAttestation.base}/status`,
    postGlobalStatus: () =>
      `${ENDPOINT.formationAttestation.base}/global-status`,
  },

  formationInstitute: {
    base: "/formation-institute",
    getAll: () => `${ENDPOINT.formationInstitute.base}`,
    postCreate: () => `${ENDPOINT.formationInstitute.base}/create`,
    postDelete: () => `${ENDPOINT.formationInstitute.base}/delete`,
  },

  payementController: {
    base: "/payement",
    postTransferDone: () => `${ENDPOINT.payementController.base}/transfer-done`,
    postTransferStatus: () =>
      `${ENDPOINT.payementController.base}/transfer-status`,
    getPayByCardRedirect: () =>
      `${ENDPOINT.payementController.base}/pay-by-card-redirect`,
  },

  question: {
    base: () => `/question`,
    getAllPaginated: () => ENDPOINT.question.base(),
    getFilters: () => ENDPOINT.question.base() + "/filters",
    getById: (questionId: number) =>
      `${ENDPOINT.question.base()}/id/${questionId}`,
    postUpload: (eraseOldQuestions: boolean) =>
      ENDPOINT.question.base() +
      "/upload?eraseOldQuestions=" +
      String(eraseOldQuestions),
    getSearch: (number: number) =>
      ENDPOINT.question.base() + "/search/" + number.toString(),
  },

  queues: {
    base: () => `/queues`,
    getJobs: () => ENDPOINT.queues.base() + "/jobs",
  },

  reducedPrice: {
    base: "/reduced-price",
    postNewDocument: () => `${ENDPOINT.reducedPrice.base}/new-document`,
    postStatus: () => `${ENDPOINT.reducedPrice.base}/status`,
  },

  registration: {
    base: "/registration",
    getInfo: (registrationId: number) =>
      `${ENDPOINT.registration.base}/info/${registrationId}`,
    postPreTestStatus: () => `${ENDPOINT.registration.base}/pre-test-status`,
    postUnregister: () => `${ENDPOINT.registration.base}/unregister`,
    postReport: () => `${ENDPOINT.registration.base}/report`,
    postSetAdditionalTime: () =>
      `${ENDPOINT.registration.base}/additional-time`,
    getAllPaginated: () => `${ENDPOINT.registration.base}/all`,
    getPossibleExamIds: () => `${ENDPOINT.registration.base}/possible-exam-ids`,
    getExamPerimeters: () => `${ENDPOINT.registration.base}/exam-perimeters`,
    patchModifyProperties: (registrationId?: number) =>
      `${ENDPOINT.registration.base}${
        registrationId === undefined ? "" : `/${registrationId}`
      }`,
  },

  qcm: {
    base: "/qcm",
    getByDate: () => `${ENDPOINT.qcm.base}/by-date`,
    getByDateDetailed: ({ date }: DateDto) =>
      `${ENDPOINT.qcm.base}/by-date-detailed/${format(date, "yyyy-MM-dd")}`,
    postGenerate: () => `${ENDPOINT.qcm.base}/generate`,
    getQuestions: () => `${ENDPOINT.qcm.base}/questions`,
    postReplaceQuestions: () => `${ENDPOINT.qcm.base}/replace-questions`,
  },
};

export { ENDPOINT };
