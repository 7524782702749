import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import { DocumentType } from "@/api/dto/document-type.enum";
import { ValidationStatus } from "@/api/enums/ValidationStatus";
import { keyFactory } from "@/api/keyFactory";
import { useMutationReducedPriceNewDocument } from "@/api/reduced-price/reduced-price";
import { Badge } from "@/components/atoms/Badge";
import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/atoms/Dialog";
import {
  Form,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/atoms/Form";
import GreenCheck from "@/components/atoms/GreenCheck";
import DropzoneMolecule from "@/components/molecules/Dropzone";
import FileLink from "@/components/molecules/FileLink";
import { Card, CardTitle } from "@/components/molecules/RegistrationCard/Card";
import { file } from "@/constants/zodTypes";
import useFileUpload from "@/hooks/FileUpload";
import { PayementUtil } from "@/lib/payement-util";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

interface ReducedPriceCardProps {
  registration: CandidateRegistrationDtoClient;
}

const reducedPriceSchema = z.object({ file: file() });

const ReducedPriceCard = ({
  registration: {
    reducedPriceStatus,
    id,
    delegation,
    reducedPriceDocument,
    payementStatus,
  },
}: ReducedPriceCardProps) => {
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const queryClient = useQueryClient();

  const { ensureUploaded, uploadIsPending } = useFileUpload(
    DocumentType.REDUCED_PRICE_JUSTIFICATION
  );

  const form = useForm<z.infer<typeof reducedPriceSchema>>({
    resolver: zodResolver(reducedPriceSchema),
    defaultValues: { file: reducedPriceDocument ?? new File([""], "") },
  });

  const onError = () => {
    toast.error("Échec de la requête", {
      description: "Votre justificatif n'a pas pu être déposé.",
    });
  };

  const uploadMutation = useMutationReducedPriceNewDocument({
    onSuccess: () => {
      toast.success("Justificatif déposé", {
        description: "Votre justificatif a bien été déposé.",
      });

      queryClient.invalidateQueries({
        queryKey: keyFactory.candidate.registrations(),
      });
      setUploadDialogOpen(false);
    },
    onError,
  });

  const onSubmit = async (values: z.infer<typeof reducedPriceSchema>) => {
    const { data, error, success } = reducedPriceSchema.safeParse(values);

    if (!success) {
      console.error("can't parse form, aborting :", error);
      return;
    }

    const [file] = await ensureUploaded(data.file);
    if (!file) {
      console.error("error while uploading file");
      onError();
      return;
    }

    uploadMutation.mutate({
      registrationId: id,
      file,
    });
  };

  return (
    <>
      <Dialog open={uploadDialogOpen} onOpenChange={setUploadDialogOpen}>
        <DialogContent className="max-w-2xl">
          <DialogHeader>
            <DialogTitle>Dépôt de justificatif du tarif réduit</DialogTitle>
            <DialogDescription hidden={true}>
              Formulaire pour déposer un justificatif du tarif réduit.
            </DialogDescription>
          </DialogHeader>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="flex w-full flex-col gap-2"
            >
              <FormField
                name="file"
                control={form.control}
                render={() => (
                  <FormItem className="w-full">
                    <DropzoneMolecule canBeRemoved={true}>
                      <span className="text-center">
                        <span className="text-brand-700">Déposez</span> ou
                        cliquez pour déposer votre justificatif étudiant ou
                        demandeur d'emploi <br />
                        <span className="text-gray-600 text-sm font-normal">
                          Pour les demandeurs d'emploi, le justificatif doit
                          dater de moins de 3 mois. Il sera contrôlé par le
                          CIFMD. Si celui-ci n'est pas recevable, le tarif plein
                          vous sera appliqué.
                        </span>
                      </span>
                    </DropzoneMolecule>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button
                type="submit"
                disabled={uploadMutation.isPending || uploadIsPending}
              >
                {uploadMutation.isPending
                  ? "Envoi de la demande..."
                  : uploadIsPending
                  ? "Envoi du document...."
                  : "Valider le dépôt"}
              </Button>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
      <Card>
        <div className="flex flex-row justify-between w-full items-center">
          <div className="flex flex-row gap-2">
            <CardTitle>Justificatif de tarif réduit</CardTitle>
            {delegation ||
            reducedPriceStatus === ValidationStatus.UNAVAILABLE ? (
              <Badge variant="white">Non disponible</Badge>
            ) : reducedPriceStatus === ValidationStatus.REFUSED ? (
              <Badge variant="red">Nouveau justificatif requis</Badge>
            ) : reducedPriceStatus === ValidationStatus.PENDING ? (
              <Badge variant="orange">En attente de validation</Badge>
            ) : reducedPriceStatus === ValidationStatus.VALIDATED ? (
              <GreenCheck />
            ) : (
              false
            )}
          </div>
          {!delegation &&
            !PayementUtil.isPaid(payementStatus) &&
            ![ValidationStatus.PENDING, ValidationStatus.VALIDATED].includes(
              reducedPriceStatus
            ) && (
              <Button onClick={() => setUploadDialogOpen(true)}>Déposer</Button>
            )}
        </div>{" "}
        {reducedPriceStatus !== ValidationStatus.UNAVAILABLE && (
          <FileLink file={reducedPriceDocument} showName={false} />
        )}
      </Card>
    </>
  );
};

export default ReducedPriceCard;
