import { ENDPOINT } from "@/constants/endpoints";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { PaginationResponseDto } from "../dto/pagination.dto";
import { keyFactory } from "../keyFactory";
import { CancelQuestionDto } from "./dto/cancel-question.dto";
import {
  CandidateExamResultDtoClient,
  CandidateExamResultPaginationRequestDto,
} from "./dto/candidate-exam-result-paginated.dto";
import { ModifyGradeDto } from "./dto/modify-grade.dto";

// generate documents

const generateDocuments = async (examId: number) => {
  await axios.post<void>(ENDPOINT.examResult.postGenerateDocuments(), {
    examId,
  });
};

export const useMutationExamResultGenerateDocuments = (
  props?: Partial<UseMutationOptions<void, AxiosError, number>>
) =>
  useMutation({
    mutationFn: generateDocuments,
    ...props,
  });

// cancel question

const cancelQuestion = async (body: CancelQuestionDto) => {
  await axios.post<void>(ENDPOINT.examResult.postCancelQuestion(), body);
};

export const useMutationExamResultCancelQuestion = (
  props?: Partial<UseMutationOptions<void, AxiosError, CancelQuestionDto>>
) =>
  useMutation({
    mutationFn: cancelQuestion,
    ...props,
  });

// modify grade

const modifyGrade = async (body: ModifyGradeDto) => {
  await axios.post<void>(ENDPOINT.examResult.postModifyGrade(), body);
};

export const useMutationExamResultModifyGrade = (
  props?: Partial<UseMutationOptions<void, AxiosError, ModifyGradeDto>>
) =>
  useMutation({
    mutationFn: modifyGrade,
    ...props,
  });

// candidate results paginated

const candidatePaginated = async (
  query: CandidateExamResultPaginationRequestDto,
  candidateId?: number
) =>
  await axios
    .get<PaginationResponseDto<CandidateExamResultDtoClient>>(
      ENDPOINT.examResult.getCandidate(candidateId),
      {
        params: query,
      }
    )
    .then((res) => res.data);

export const useQueryExamResultCandidatePaginated = (
  query: CandidateExamResultPaginationRequestDto,
  candidateId?: number,
  props?: Partial<
    UseQueryOptions<PaginationResponseDto<CandidateExamResultDtoClient>>
  >
) =>
  useQuery({
    queryFn: () => candidatePaginated(query, candidateId),
    queryKey: keyFactory.examResult.candidate(query, candidateId),
    ...props,
  });

// upload edc results

interface UploadEdcResultsDto {
  examId: number;
  files: File[];
}

export const csvFileTypes = [
  "text/csv",
  "application/vnd.ms-excel",
  "application/csv",
  "text/x-csv",
  "application/x-csv",
  "text/comma-separated-values",
  "text/x-comma-separated-values",
];

const edcResults = async ({ examId, files }: UploadEdcResultsDto) => {
  for (const { type } of files)
    if (!csvFileTypes.includes(type))
      throw new Error("Les fichier doivent être au format CSV");

  const formData = new FormData();

  // attach files
  for (const file of files) formData.append("files", file);

  // attach additional data
  formData.append("examId", examId.toString());

  await axios.post<void>(ENDPOINT.examResult.postEdcResults(), formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const useMutationExamResultUploadEdcResults = (
  props?: Partial<UseMutationOptions<void, AxiosError, UploadEdcResultsDto>>
) =>
  useMutation({
    mutationFn: edcResults,
    ...props,
  });
