import { io } from "socket.io-client";
import { useSocketStore } from "./useSocketStore";

const API_URL = import.meta.env.VITE_API_URL;
const API_PORT = import.meta.env.VITE_API_PORT;

const CREDENTIALS_NAMESPACES = [""];

const { setConnected, setNamespace: zustandSetNamespace } =
  useSocketStore.getState();

const createSocket = (namespace: string = "") => {
  const socket = io(`${API_URL}:${API_PORT}/${namespace}`, {
    withCredentials: CREDENTIALS_NAMESPACES.includes(namespace),
    autoConnect: false,
  });

  socket.on("connect", () => {
    setConnected(true);
    console.log("Connected to WebSocket server");
  });

  socket.on("connect_error", (error: any) => {
    console.error("Error connecting to WebSocket server", error);
  });

  socket.on("disconnect", () => {
    setConnected(false);
    console.log("Disconnected from WebSocket server");
  });

  socket.on("message", (data: string) => {
    console.log("Received message:", data);
  });

  socket.on("exception", (err) => {
    console.error("Websocket exception:", err);
  });

  return socket;
};

let socket = createSocket();

const setNamespace = (namespace?: string) => {
  if (namespace === useSocketStore.getState().namespace) return;

  console.log(`setting namespace to ${namespace}`); // TODO remove console.log
  zustandSetNamespace(namespace);
  if (socket) socket.disconnect();
  socket = createSocket(namespace);
};

export { setNamespace, socket };
