import { useQueryCandidateArchivedPaginated } from "@/api/candidate/candidate";
import { Skeleton } from "@/components/atoms/Skeleton";
import LoadingError from "@/components/molecules/LoadingError";
import SmartPagination from "@/components/molecules/SmartPagination";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/molecules/Table";
import { ROUTE } from "@/constants/routes";
import useTablePlaceholderRows from "@/hooks/table-placeholder-rows";
import usePersistent from "@/hooks/use-persistent";
import { cn } from "@/lib/utils";
import { keepPreviousData } from "@tanstack/react-query";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useLocation, useNavigate } from "react-router-dom";
import { columns } from "./columns";

interface ArchivedCandidatesTableProps {
  searchText?: string;
}

const ArchivedCandidatesTable = ({
  searchText,
}: ArchivedCandidatesTableProps) => {
  const [pageIndex, setPageIndex] = usePersistent(
    "administration-archived-candidates-pageIndex",
    0
  );
  const navigate = useNavigate();
  const location = useLocation();

  const { data, isLoading } = useQueryCandidateArchivedPaginated(
    {
      pageIndex,
      searchText,
    },
    {
      placeholderData: keepPreviousData,
    }
  );

  const [rows, is_placeholder] = useTablePlaceholderRows(data);

  const table = useReactTable({
    data: rows,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 1,
      minSize: 1,
    },
  });

  if (!data) {
    if (isLoading) return <Skeleton className="h-full w-full" />;
    else return <LoadingError>Erreur de chargement </LoadingError>;
  }

  return (
    <div className="flex flex-col w-full gap-5">
      <div className="rounded-lg overflow-auto border border-gray-200">
        <Table className="text-gray-600 font-medium text-sm leading-6">
          <TableHeader className="items-center bg-gray-50">
            <TableRow>
              {table.getHeaderGroups()[0].headers.map((header) => (
                <TableHead
                  key={header.id}
                  style={{ width: `${header.getSize()}px` }}
                >
                  <div className="flex flex-row items-center gap-3 text-xs">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.map((row, i) => (
              <TableRow
                key={row.id}
                className={cn({
                  "bg-gray-50": i % 2,
                  "opacity-0 border-none": is_placeholder(i),
                  "cursor-pointer": !is_placeholder(i),
                })}
                onClick={
                  is_placeholder(i)
                    ? undefined
                    : () =>
                        navigate(
                          ROUTE.admin.dashboard.candidate.root(row.original.id),
                          { state: { from: location.pathname } }
                        )
                }
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} className="h-14 py-0">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <SmartPagination
        currentPage={pageIndex}
        onPageChange={setPageIndex}
        totalPages={data.totalPages}
      />
    </div>
  );
};

export default ArchivedCandidatesTable;
