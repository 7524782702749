import { useQueryCertificateAll } from "@/api/certificate/certificate";
import { CertificateStatus } from "@/api/enums/CertificateStatus";
import { RenewingType } from "@/api/enums/RenewingType";
import useChildForm from "@/hooks/ChildForm";
import { Perimeter } from "@/lib/perimeter";
import { useEffect, useMemo, useState } from "react";
import { PerimeterForm } from "../../PerimeterForm/PerimeterForm";
import CertificatesSwitches from "../CertificatesSwitches";
import RenewingTypeRadio from "./RenewingTypeRadio";

interface RenewingPropertiesFormProps {
  candidateId?: number;
  fieldName?: string;
}

const RenewingPropertiesForm = ({
  fieldName,
  candidateId,
}: RenewingPropertiesFormProps) => {
  const [renewalAllowedPerimeter, setRenewalAllowedPerimeter] =
    useState<Perimeter>(Perimeter.empty());
  const { transformPath, watch, setValue } = useChildForm(fieldName);

  const certificatesQuery = useQueryCertificateAll(candidateId);

  const renewingType = watch(transformPath("renewingType"));
  const renewingCertificatesIDs = watch(
    transformPath("renewingCertificatesIDs")
  );

  const validCertificates = useMemo(
    () =>
      certificatesQuery.data?.filter(
        ({ expirationDate, status }) =>
          new Date(expirationDate) >= new Date() &&
          (status as CertificateStatus) === CertificateStatus.VALIDATED
      ) ?? [],
    [certificatesQuery.data]
  );

  // merging perimeters is allowed only if the difference is
  // in merchandises or transport modes, not both
  const renewalAllowedCertificates = useMemo(() => {
    if (
      renewingCertificatesIDs.length === 0 ||
      renewingType !== RenewingType.FUSION
    )
      return validCertificates;

    // if there is only one certificate, it is allowed to merge
    // with any other certificate that doesn't differ in both
    // merchandises and transport modes

    if (renewingCertificatesIDs.length === 1) {
      const p = validCertificates.find(
        ({ id }) => id === renewingCertificatesIDs[0]
      );

      if (!p) throw new Error("no certificate found");

      return validCertificates.filter(({ perimeter }) => {
        const difference = Perimeter.difference(
          Perimeter.from(p.perimeter),
          Perimeter.from(perimeter)
        );

        return (
          difference.merchandises.length === 0 ||
          difference.transportModes.length === 0
        );
      });
    }

    // if there are multiple certificates, they must all share
    // the same merchandises or the same transport modes

    const p0 = validCertificates.find(
      ({ id }) => id === renewingCertificatesIDs[0]
    );
    const p1 = validCertificates.find(
      ({ id }) => id === renewingCertificatesIDs[1]
    );

    if (!p0 || !p1) throw new Error("no certificate found");

    const difference = Perimeter.difference(
      Perimeter.from(p0.perimeter),
      Perimeter.from(p1.perimeter)
    );

    return validCertificates.filter(({ perimeter }) => {
      const d = Perimeter.difference(
        Perimeter.from(p0.perimeter),
        Perimeter.from(perimeter)
      );

      if (d.merchandises.length !== 0 && d.transportModes.length !== 0)
        return false;
      if (d.merchandises.length !== 0 && difference.merchandises.length === 0)
        return false;
      if (
        d.transportModes.length !== 0 &&
        difference.transportModes.length === 0
      )
        return false;

      return true;
    });
  }, [validCertificates, renewingType, renewingCertificatesIDs]);

  // auto-select allowed fields
  useEffect(() => {
    const newAllowedPerimeter = Perimeter.union(
      ...(certificatesQuery.data
        ?.filter(({ id }) => renewingCertificatesIDs.includes(id as number))
        .map(({ perimeter }) => Perimeter.from(perimeter)) || [])
    );

    if ([RenewingType.SAME, RenewingType.FUSION].includes(renewingType)) {
      setValue(transformPath("perimeter"), newAllowedPerimeter);
    } else {
      const restrictedPerimeter = Perimeter.from(
        watch(transformPath("perimeter"))
      ).restrictByOther(newAllowedPerimeter);
      setValue(transformPath("perimeter"), restrictedPerimeter);
    }

    setRenewalAllowedPerimeter(newAllowedPerimeter);
  }, [
    renewingType,
    renewingCertificatesIDs,
    setValue,
    watch,
    certificatesQuery.data,
    transformPath,
  ]);

  // avoid multiple selections if forbidden
  useEffect(() => {
    if ([RenewingType.SAME, RenewingType.REDUCED].includes(renewingType)) {
      setValue(
        transformPath("renewingCertificatesIDs"),
        watch(transformPath("renewingCertificatesIDs")).slice(0, 1)
      );
    }
  }, [renewingType, setValue, watch, transformPath]);

  return (
    <>
      <RenewingTypeRadio fieldName={transformPath("renewingType")} />
      {certificatesQuery.isLoading ? (
        <div>Chargement des certificats...</div>
      ) : certificatesQuery.isError ? (
        <div>Erreur lors du chargement des certificats.</div>
      ) : (
        <>
          <CertificatesSwitches
            certificates={renewalAllowedCertificates}
            unique={[RenewingType.SAME, RenewingType.REDUCED].includes(
              renewingType
            )}
            fieldName={transformPath("renewingCertificatesIDs")}
            titleElement={<h2>Vos certificat(s) à renouveler</h2>}
          />
          <PerimeterForm
            fieldName={transformPath("perimeter")}
            disabledPerimeter={
              [RenewingType.SAME, RenewingType.FUSION].includes(renewingType)
                ? Perimeter.full()
                : Perimeter.empty()
            }
            displayedPerimeter={renewalAllowedPerimeter}
            uncheckedAreRed={renewingType === RenewingType.REDUCED}
          />
        </>
      )}
    </>
  );
};

export default RenewingPropertiesForm;
