import { ExtensionType } from "@/api/enums/ExtensionType";
import { Button } from "@/components/atoms/Button";
import { FormControl, FormField, FormItem } from "@atoms/Form";
import { useFormContext } from "react-hook-form";

interface ExtensionTypeButtonsProps {
  fieldName: string;
}

const ExtensionTypeButtons = ({ fieldName }: ExtensionTypeButtonsProps) => {
  const { control } = useFormContext();

  return (
    <div className="flex flex-col w-full gap-2">
      <h2>Je souhaite ajouter...</h2>
      <FormField
        control={control}
        name={fieldName}
        render={({ field }) => (
          <FormItem className="w-full">
            <div className="flex flex-row w-full gap-2">
              <FormControl>
                <Button
                  className="flex w-full"
                  type="button"
                  variant={
                    field.value === ExtensionType.TRANSPORTS
                      ? "default"
                      : "white"
                  }
                  onClick={() => field.onChange(ExtensionType.TRANSPORTS)}
                >
                  {"Un ou des modes de transports"}
                </Button>
              </FormControl>
              <FormControl>
                <Button
                  type="button"
                  className="flex w-full"
                  variant={
                    field.value === ExtensionType.MERCHANDISES
                      ? "default"
                      : "white"
                  }
                  onClick={() => field.onChange(ExtensionType.MERCHANDISES)}
                >
                  {"Une ou des classes dangereuses"}
                </Button>
              </FormControl>
            </div>
          </FormItem>
        )}
      />
    </div>
  );
};

export default ExtensionTypeButtons;
