import { CertificateDtoClient } from "@/api/dto/certificate.dto";
import { Perimeter } from "@/lib/perimeter";
import { formatDate } from "@/lib/utils";
import { GraduationCap } from "lucide-react";

interface CertificateDisplayProps {
  certificate: CertificateDtoClient;
}

const CertificateDisplay = ({ certificate }: CertificateDisplayProps) => (
  <div className="flex flex-row gap-2">
    <GraduationCap className="w-6 h-6 text-gray-900 stroke-1" />
    <div className="flex flex-row text-gray-700  gap-6 font-bold text-sm leading-6">
      <span>
        N° de certificat:{" "}
        <span className="font-medium">
          {certificate.number.padStart(7, "")}
        </span>
      </span>
      <span>
        Fin de validité:{" "}
        <span className="font-medium">
          {formatDate(certificate.expirationDate)}
        </span>
      </span>
      <span>
        Périmètre:{" "}
        <span className="font-medium">
          {Perimeter.toCode(certificate.perimeter)}
        </span>
      </span>
    </div>
  </div>
);

export default CertificateDisplay;
