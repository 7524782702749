import {
  useQueryCandidateCanRegister,
  useQueryCandidateRegistrations,
} from "@/api/candidate/candidate";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/atoms/HoverCard";
import { Skeleton } from "@/components/atoms/Skeleton";
import { Tabs, TabsList, TabsTrigger } from "@/components/atoms/Tabs";
import BlueNavLink from "@/components/molecules/BlueNavLink";
import LoadingError from "@/components/molecules/LoadingError";
import { ROUTE } from "@/constants/routes";
import usePersistent from "@/hooks/use-persistent";
import { MyExamsTab } from "./my-exams-tab.enum";
import NoExams from "./NoExamsMessage";
import Registrations from "./Registrations/Registrations";
import PayementAcknowledgeDialog from "./PayementAcknowledgeDialog";

const Home = () => {
  const [currentTab, setCurrentTab] = usePersistent(
    "my-exams-tab",
    MyExamsTab.ToCome
  );

  const registrationsQuery = useQueryCandidateRegistrations();
  const canRegisterQuery = useQueryCandidateCanRegister();

  if (!registrationsQuery.data) {
    return (
      <div className="w-full h-full p-8">
        {registrationsQuery.isLoading ? (
          <Skeleton className="h-full w-full bg-gray-300" />
        ) : (
          <LoadingError>Erreur de chargement des entreprises</LoadingError>
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-col items-start gap-6 self-stretch py-8 px-28">
      <PayementAcknowledgeDialog />
      <div className="flex flex-row w-full justify-between items-center">
        <div className="flex flex-col items-start">
          <h1 className="text-2xl">Mes examens</h1>
          <span className="text-gray-600 text-sm font-">
            M'inscrire et visualiser mes résultats aux examens.
          </span>
        </div>
        {registrationsQuery.data.length > 0 && (
          <BlueNavLink
            to={ROUTE.candidate.dashboard.exams.register().root()}
            // candidate can't register to multiple exams at the same time
            disabled={!canRegisterQuery.data}
          >
            {!canRegisterQuery.data ? (
              <HoverCard openDelay={0}>
                <HoverCardTrigger>
                  <span>S'inscrire à une session d'examen</span>
                </HoverCardTrigger>
                <HoverCardContent>
                  <span>Vous êtes déjà inscrit à une session d'examen.</span>
                </HoverCardContent>
              </HoverCard>
            ) : (
              <span>S'inscrire à une session d'examen</span>
            )}
          </BlueNavLink>
        )}
      </div>
      {registrationsQuery.data.length === 0 && <NoExams />}
      {registrationsQuery.data.length > 0 && (
        <>
          <Tabs
            value={currentTab}
            className="flex flex-col gap-6 w-full"
            onValueChange={(t) => setCurrentTab(t as MyExamsTab)}
          >
            <TabsList className="p-0.5 h-full w-fit">
              <TabsTrigger value={MyExamsTab.ToCome}>
                Mes examens à venir
              </TabsTrigger>
              <TabsTrigger value={MyExamsTab.Results}>
                Mes documents et résultats
              </TabsTrigger>
            </TabsList>
          </Tabs>
          <Registrations tab={currentTab} />
        </>
      )}
    </div>
  );
};

export default Home;
