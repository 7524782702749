import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import { RegistrationType } from "@/api/enums/RegistrationType";
import { RegistrationModifyPropertiesDto } from "@/api/registration/dto/registration-modify-properties.dto";
import {
  extensionPropertiesFromDto,
  extensionPropertiesSchema,
  extensionPropertiesToDto,
} from "@/components/molecules/RegistrationForms/ExtensionProperties/extension-properties.schema";
import { initialPropertiesSchema } from "@/components/molecules/RegistrationForms/InitialProperties/initial-properties.schema";
import { renewingPropertiesSchema } from "@/components/molecules/RegistrationForms/RenewingProperties/renewing-properties.schema";
import { z } from "zod";

const modifyPropertiesSchema = z.discriminatedUnion("type", [
  z.object({
    type: z.literal(RegistrationType.INITIAL),
    initialProperties: initialPropertiesSchema,
  }),
  z.object({
    type: z.literal(RegistrationType.EXTENSION),
    extensionProperties: extensionPropertiesSchema,
  }),
  z.object({
    type: z.literal(RegistrationType.RENEW),
    renewingProperties: renewingPropertiesSchema,
  }),
]);

type ModifyPropertiesSchema = z.infer<typeof modifyPropertiesSchema>;

const modifyPropertiesDefaultValues = ({
  initialProperties,
  extensionProperties,
  renewingProperties,
  type,
}: CandidateRegistrationDtoClient): ModifyPropertiesSchema => {
  switch (type) {
    case RegistrationType.EXTENSION:
      if (!extensionProperties)
        throw new Error(
          "Extension properties are required to modify properties."
        );
      return {
        type,
        extensionProperties: extensionPropertiesFromDto(extensionProperties),
      };

    case RegistrationType.RENEW:
      if (!renewingProperties)
        throw new Error(
          "Renewing properties are required to modify properties."
        );
      return {
        type,
        renewingProperties,
      };

    case RegistrationType.INITIAL:
      if (!initialProperties)
        throw new Error(
          "Initial properties are required to modify properties."
        );
      return {
        type,
        initialProperties,
      };
  }
};

const modifyPropertiesToDto = (
  data: ModifyPropertiesSchema
): RegistrationModifyPropertiesDto => {
  switch (data.type) {
    case RegistrationType.EXTENSION:
      return {
        extensionProperties: extensionPropertiesToDto(data.extensionProperties),
      };

    case RegistrationType.RENEW:
      return { renewingProperties: data.renewingProperties };

    case RegistrationType.INITIAL:
      return { initialProperties: data.initialProperties };
  }
};

export {
  modifyPropertiesDefaultValues,
  modifyPropertiesSchema,
  modifyPropertiesToDto,
};
export type { ModifyPropertiesSchema };
