import { MerchandiseClass } from "@/api/enums/merchandise-class.enum";
import useChildForm from "@/hooks/ChildForm";
import { Perimeter } from "@/lib/perimeter";
import { PerimeterForm } from "../../PerimeterForm/PerimeterForm";
import UTCCombobox from "../UTCCombobox";

interface InitialPropertiesFormProps {
  fieldName?: string;
}

const InitialPropertiesForm = ({ fieldName }: InitialPropertiesFormProps) => {
  const { transformPath } = useChildForm(fieldName);

  return (
    <>
      <UTCCombobox fieldName={transformPath("utc")} />
      <PerimeterForm
        fieldName={transformPath("perimeter")}
        // hydrocarbons not proposed anymore in initial
        hiddenPerimeter={new Perimeter([], [MerchandiseClass.HYDROCARBONS])}
      />
    </>
  );
};

export default InitialPropertiesForm;
