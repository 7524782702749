import { CertificateDtoClient } from "@/api/dto/certificate.dto";
import { GreenSwitchArrayValue } from "@/components/atoms/GreenSwitch";
import useChildForm from "@/hooks/ChildForm";
import { FormMessage } from "@atoms/Form";
import { ReactNode } from "react";
import CertificateDisplay from "./CertificateDisplay";

interface CertificatesSwitchesProps {
  fieldName: string;
  certificates: CertificateDtoClient[];
  titleElement?: ReactNode;
  isArray?: boolean;
  unique?: boolean;
}

const CertificatesSwitches = ({
  fieldName,
  certificates,
  titleElement,
  isArray = true,
  unique = false,
}: CertificatesSwitchesProps) => {
  const { filteredErrors } = useChildForm(fieldName);

  return (
    <div className="w-full">
      <div className={`flex flex-col w-full gap-2 flex-start self-stretch`}>
        {titleElement}
        {filteredErrors && (
          <p className="text-red-600 text-sm">
            {filteredErrors.message as string}
          </p>
        )}
        <FormMessage />

        {certificates.map((certificate) => (
          <GreenSwitchArrayValue
            key={certificate.file.s3Key}
            name={fieldName}
            value={(certificate.id as number).toString()}
            transform={(val) => Number(val)}
            isArray={isArray}
            unique={unique}
          >
            <CertificateDisplay certificate={certificate} />
          </GreenSwitchArrayValue>
        ))}
      </div>
    </div>
  );
};

export default CertificatesSwitches;
