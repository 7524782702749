import {
  useMutationDraftConfirm,
  useMutationDraftCreate,
  useMutationDraftSetOptions,
  useQueryDraftOptions,
} from "@/api/draft/draft";
import { ExamOptionsDto } from "@/api/draft/dto/exam-options.dto";
import { keyFactory } from "@/api/keyFactory";
import { Skeleton } from "@/components/atoms/Skeleton";
import LoadingError from "@/components/molecules/LoadingError";
import { ROUTE } from "@/constants/routes";
import usePersistent from "@/hooks/use-persistent";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@atoms/BreadCrumb";
import ConfirmDialog from "@molecules/ConfirmDialog";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosError, HttpStatusCode } from "axios";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import ProgressBar from "./ProgressBar/ProgressBar";
import { RegisterStep, registerStepToPage } from "./register-step.enum";
import { RegisterContext } from "./RegisterContext";

const Register = () => {
  const { id: candidateIdStr } = useParams();
  const candidateId = Number(candidateIdStr)
    ? Number(candidateIdStr)
    : undefined;

  // loading or discarding draft
  const [loadDraftDialogOpen, setLoadDraftDialogOpen] = useState(false);
  const [userWasPrompted, setUserWasPrompted] = useState(false);
  // steps handling
  const [step, setStep] = usePersistent(
    "register-step",
    RegisterStep.CheckInfo
  );
  const [maxStep, setMaxStep] = usePersistent(
    "register-max-step",
    RegisterStep.CheckInfo
  );
  // used to compare new and old states, and check if changes have been made
  // this permits to do fewer api calls
  const [previousRegistrationDraft, setPreviousRegistrationDraft] =
    useState<ExamOptionsDto | null>(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  //////////////////
  // api calls
  //////////////////

  const getOptionsQuery = useQueryDraftOptions(candidateId, {
    retry(_, error) {
      // create draft if there isn't any
      if (
        error instanceof AxiosError &&
        error.response?.status === HttpStatusCode.Conflict
      ) {
        createResetDraftMutation.mutate(candidateId);
        return false;
      }

      return true;
    },
  });
  const createResetDraftMutation = useMutationDraftCreate({
    onSuccess: (draft) => {
      queryClient.invalidateQueries({
        queryKey: keyFactory.draft.options(),
      });

      queryClient.invalidateQueries({
        queryKey: keyFactory.candidate.registrations(),
      });

      setStep(RegisterStep.CheckInfo);
      setMaxStep(RegisterStep.CheckInfo);
      setUserWasPrompted(true);
      setPreviousRegistrationDraft(draft);
      getOptionsQuery.refetch();
    },
  });
  const setOptionsMutation = useMutationDraftSetOptions({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: keyFactory.draft.options(),
      });
    },
  });
  const confirmDraftMutation = useMutationDraftConfirm({
    onSuccess: async () => {
      setStep(RegisterStep.CheckInfo);
      setMaxStep(RegisterStep.CheckInfo);
      if (candidateId)
        navigate(ROUTE.admin.dashboard.candidate.root(candidateId));
      else navigate(ROUTE.candidate.dashboard.exams.home());

      queryClient.invalidateQueries({
        queryKey: keyFactory.draft.options(),
      });

      queryClient.invalidateQueries({
        queryKey: keyFactory.candidate.base(),
      });

      queryClient.invalidateQueries({
        queryKey: keyFactory.registration.base(),
      });

      toast.success("Inscription réussie", {
        description:
          'Vous pouvez consulter le statut de votre inscription depuis l\'onglet "Mes examens"',
      });
    },
    onError: (error) => {
      toast.error("Votre inscription n'a pas pu être validée", {
        description: error.response?.data?.message,
      });
    },
  });

  //////////////////
  // navigation
  //////////////////

  // navigate to change page

  useEffect(() => {
    if (setOptionsMutation.isPending || confirmDraftMutation.isPending) return;
    navigate(registerStepToPage(candidateId)[step]);
  }, [
    step,
    navigate,
    setOptionsMutation.isPending,
    confirmDraftMutation.isPending,
    candidateId,
  ]);

  // ask user to continue draft

  useEffect(() => {
    if (!getOptionsQuery.isSuccess) return;
    if (userWasPrompted) return;
    setLoadDraftDialogOpen(true);
    setPreviousRegistrationDraft(getOptionsQuery.data);
  }, [getOptionsQuery.data, getOptionsQuery.isSuccess, userWasPrompted]);

  // loading draft mechanism

  const handleLoadDraftResult = (userWantsToLoadDraft: boolean) => {
    if (!userWantsToLoadDraft) createResetDraftMutation.mutate(candidateId);
    setUserWasPrompted(true);
    setLoadDraftDialogOpen(false);
  };

  // on page change

  const onNextStep = async (goNext: boolean) => {
    if (step === RegisterStep.Acknowledgment && goNext) {
      confirmDraftMutation.mutate(candidateId);
      return;
    }

    const newStep = step + (goNext ? 1 : -1);
    setStep(newStep);
    if (newStep > maxStep) setMaxStep(newStep);
  };

  //////////////////
  // context
  //////////////////

  const changeDraft = (newOptions: ExamOptionsDto) => {
    const newDraft = {
      ...previousRegistrationDraft,
      ...newOptions,
    };

    // minimum api calls to change draft
    if (_.isEqual(newDraft, previousRegistrationDraft)) {
      onNextStep(true);
      return;
    }

    setOptionsMutation.mutate(
      {
        candidateId,
        options: newOptions,
      },
      {
        onSuccess: () => {
          onNextStep(true);
        },
        onError: () => {
          toast.error(
            "Une erreur est survenue lors de la modification du brouillon"
          );
        },
      }
    );
  };

  const context: RegisterContext | undefined = getOptionsQuery.data
    ? {
        registrationDraft: getOptionsQuery.data,
        changeDraft,
        onNextStep,
        pending: setOptionsMutation.isPending || confirmDraftMutation.isPending,
        candidateId,
      }
    : undefined;

  //////////////////
  // component
  //////////////////

  if (!context)
    return getOptionsQuery.isLoading || createResetDraftMutation.isPending ? (
      <div className="pt-8 px-28 pb-2 w-full h-full">
        <Skeleton className="h-full w-full bg-gray-300" />
      </div>
    ) : (
      <LoadingError>Erreur de chargement du brouillon</LoadingError>
    );

  return (
    <>
      <ConfirmDialog
        open={loadDraftDialogOpen}
        onResult={handleLoadDraftResult}
        title="Inscription en cours"
        message="Vous avez déjà une inscription en cours."
        validateStr="Continuer mon inscription"
        cancelStr="Supprimer et recommencer"
      />
      <div className="flex flex-row pt-8 px-28 pb-2 items-start gap-6 self-stretch">
        <div className="flex flex-col items-start gap-1 self-stretch min-w-fit">
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbPage className="text-sm font-medium text-gray-600">
                  <Link to={ROUTE.candidate.dashboard.exams.home()}>
                    Mes examens
                  </Link>
                </BreadcrumbPage>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage className="font-semibold text-sm text-brand-700">
                  S'inscrire à un examen
                </BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
          <h1 className="text-2xl">S'inscrire à un examen</h1>
          <ProgressBar changeStep={setStep} maxStep={maxStep} step={step} />
        </div>
        <div className="flex items-start gap-6 w-full">
          {getOptionsQuery.isSuccess && !loadDraftDialogOpen && (
            <Outlet context={context} />
          )}
        </div>
      </div>
    </>
  );
};

export default Register;
