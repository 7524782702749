import { ROUTE } from "@/constants/routes";

export enum RegisterStep {
  CheckInfo = 0,
  Register = 1,
  Pricing = 2,
  Payement = 3,
  Certificate = 4,
  Acknowledgment = 5,
}

export const registerStepToPage = (candidateId?: number) =>
  candidateId
    ? {
        [RegisterStep.Acknowledgment]: ROUTE.admin.dashboard.candidate
          .register(candidateId)
          .acknowledgment(),
        [RegisterStep.Certificate]: ROUTE.admin.dashboard.candidate
          .register(candidateId)
          .certificate(),
        [RegisterStep.CheckInfo]: ROUTE.admin.dashboard.candidate
          .register(candidateId)
          .checkInfo(),
        [RegisterStep.Payement]: ROUTE.admin.dashboard.candidate
          .register(candidateId)
          .payement(),
        [RegisterStep.Pricing]: ROUTE.admin.dashboard.candidate
          .register(candidateId)
          .pricing(),
        [RegisterStep.Register]: ROUTE.admin.dashboard.candidate
          .register(candidateId)
          .registerStep(),
      }
    : {
        [RegisterStep.Acknowledgment]: ROUTE.candidate.dashboard.exams
          .register()
          .acknowledgment(),
        [RegisterStep.Certificate]: ROUTE.candidate.dashboard.exams
          .register()
          .certificate(),
        [RegisterStep.CheckInfo]: ROUTE.candidate.dashboard.exams
          .register()
          .checkInfo(),
        [RegisterStep.Payement]: ROUTE.candidate.dashboard.exams
          .register()
          .payement(),
        [RegisterStep.Pricing]: ROUTE.candidate.dashboard.exams
          .register()
          .pricing(),
        [RegisterStep.Register]: ROUTE.candidate.dashboard.exams
          .register()
          .registerStep(),
      };
