import { Info } from "lucide-react";

const ExtensionMessage = () => {
  return (
    <div className="flex flex-row p-4 items-start self-stretch gap-4 border rounded-md border-gray-300 bg-white shadow">
      <Info className="w-5 h-5 flex-shrink-0" />
      <span className="font-bold">
        Vous êtes titulaire d'un certificat en cours de validité et souhaitez
        son extension avec l'ajout d'un ou deux mode(s) de transport{" "}
        <span className="text-brand-700">ou </span> d'une ou plusieurs classe(s)
        de danger. Veuillez dans ce cas sélectionner le certificat associé.
      </span>
    </div>
  );
};

export default ExtensionMessage;
