import { create } from "zustand";

interface SocketState {
  connected: boolean;
  setConnected: (status: boolean) => void;
  namespace: string | undefined;
  setNamespace: (namespace: string | undefined) => void;
}

export const useSocketStore = create<SocketState>((set) => ({
  connected: false,
  setConnected: (status) => set({ connected: status }),
  namespace: "",
  setNamespace: (namespace) => set({ namespace }),
}));
