import { ExamExtensionPropertiesDto } from "@/api/dto/exam-properties.dto";
import { ExtensionType } from "@/api/enums/ExtensionType";
import { utc } from "@/constants/zodTypes";
import { z } from "zod";
import {
  merchandisesSchema,
  transportsSchema,
} from "../../PerimeterForm/PerimeterFormSchema";

const extensionPropertiesSchema = z.object({
  utc: utc(),
  certificateId: z.coerce
    .number()
    .min(0, { message: "Veuillez choisir un certificat." }),
  extension: z.discriminatedUnion("extensionType", [
    // extend transports
    z.object({
      extensionType: z.literal(ExtensionType.TRANSPORTS),
      extendedTransportModes: transportsSchema,
    }),
    // extend merchandises
    z.object({
      extensionType: z.literal(ExtensionType.MERCHANDISES),
      extendedMerchandiseClasses: merchandisesSchema,
    }),
  ]),
});

type ExtensionPropertiesSchema = z.infer<typeof extensionPropertiesSchema>;

const extensionPropertiesDefaultValues: ExtensionPropertiesSchema = {
  utc: 1,
  certificateId: -1,
  extension: {
    extensionType: ExtensionType.MERCHANDISES,
    extendedMerchandiseClasses: [],
  },
};

const extensionPropertiesToDto = ({
  certificateId,
  extension,
  utc,
}: ExtensionPropertiesSchema): ExamExtensionPropertiesDto => ({
  certificateId,
  extensionPerimeter:
    extension.extensionType === ExtensionType.MERCHANDISES
      ? {
          merchandises: extension.extendedMerchandiseClasses,
          transportModes: [],
        }
      : {
          transportModes: extension.extendedTransportModes,
          merchandises: [],
        },
  extensionType: extension.extensionType,
  utc,
});

const extensionPropertiesFromDto = ({
  utc,
  certificateId,
  extensionPerimeter,
  extensionType,
}: ExamExtensionPropertiesDto): ExtensionPropertiesSchema => ({
  certificateId: certificateId,
  utc,
  extension: {
    extensionType: extensionType,
    extendedMerchandiseClasses: extensionPerimeter.merchandises,
    extendedTransportModes: extensionPerimeter.transportModes,
  },
});

export {
  extensionPropertiesDefaultValues,
  extensionPropertiesFromDto,
  extensionPropertiesSchema,
  extensionPropertiesToDto,
};
export type { ExtensionPropertiesSchema };
