import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import { ExamResultStatus } from "@/api/enums/exam-result-status.enum";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/atoms/Tabs";
import BlueNavLink from "@/components/molecules/BlueNavLink";
import FileLink from "@/components/molecules/FileLink";
import ExamDateCard from "@/components/molecules/RegistrationCard/ExamDateCard";
import GrayCard from "@/components/molecules/RegistrationCard/GrayCard";
import RegistrationNumberCard from "@/components/molecules/RegistrationCard/RegistrationNumberCard";
import { ROUTE } from "@/constants/routes";
import { format } from "date-fns";
import { Calendar, GraduationCap } from "lucide-react";
import { MyExamsTab } from "../../my-exams-tab.enum";
import DetailsDialog from "./DetailsDialog/DetailsDialog";
import ModifyPropertiesDialog from "./ModifyPropertiesDialog/ModifyPropertiesDialog";
import PostExamDocuments from "./PostExamDocuments";
import PreExamDocument from "./PreExamDocuments";
import ResultsCards from "./ResultsCards";
import TopMessage from "./TopMessage";

interface RegistrationCardProps {
  tab: MyExamsTab;
  registration: CandidateRegistrationDtoClient;
}

const RegistrationCard = ({ registration, tab }: RegistrationCardProps) => {
  return (
    <div className="flex flex-col gap-4 p-4 bg-white border border-gray-200 rounded-lg w-full">
      <div className="flex flex-col lg:flex-row justify-between gap-6">
        <div className="flex flex-row w-full gap-6">
          <RegistrationNumberCard
            registrationNumber={registration.registrationNumber}
            registrationType={registration.type}
          />
          <ExamDateCard date={registration.exam.date} />
          {tab === MyExamsTab.ToCome && (
            <GrayCard>
              <Calendar className="w-4 h-4 text-gray-500" />
              Date clôture des inscriptions :{" "}
              {format(
                new Date(registration.exam.registeringEndDate),
                "dd/MM/yyyy"
              )}
            </GrayCard>
          )}
        </div>
        {
          <div className="flex flex-col gap-2">
            <DetailsDialog registration={registration} />
            <ModifyPropertiesDialog registration={registration} />
          </div>
        }
      </div>
      <TopMessage registration={registration} />
      <Tabs defaultValue="documents-pre">
        {tab === MyExamsTab.Results && (
          <TabsList variant="transparent">
            <TabsTrigger variant="underlineBlue" value="documents-pre">
              Documents pré-inscription
            </TabsTrigger>
            <TabsTrigger variant="underlineBlue" value="results">
              Mes résultats
            </TabsTrigger>
            <TabsTrigger variant="underlineBlue" value="documents-post">
              Documents post-examen
            </TabsTrigger>
          </TabsList>
        )}
        <TabsContent value="documents-pre">
          <PreExamDocument registration={registration} />
        </TabsContent>
        <TabsContent value="results">
          <ResultsCards registration={registration} />
        </TabsContent>
        <TabsContent value="documents-post">
          <PostExamDocuments registration={registration} />
        </TabsContent>
      </Tabs>
      {registration.examResult &&
        [ExamResultStatus.FAILED, ExamResultStatus.MISSING].includes(
          registration.examResult.status
        ) && (
          <BlueNavLink
            to={ROUTE.candidate.dashboard.exams.register().root()}
            className="max-w-fit"
          >
            S'inscrire à un nouvel examen
          </BlueNavLink>
        )}
      {registration.examResult?.certificate && (
        <FileLink
          file={registration.examResult.certificate}
          className="bg-brand-600 w-fit py-2.5 px-3.5 rounded-lg text-white flex flex-row items-center gap-1 hover:text-gray-300 font-semibold"
        >
          <GraduationCap className="w-5 h-5" />
          <span>Télécharger mon certificat</span>
        </FileLink>
      )}
    </div>
  );
};

export default RegistrationCard;
