import { RenewingType } from "@/api/enums/RenewingType";
import { FormControl, FormField, FormItem, FormLabel } from "@atoms/Form";
import { RadioGroup, RadioGroupItem } from "@atoms/RadioGroup";
import { useFormContext } from "react-hook-form";

interface RenewingTypeRadioProps {
  fieldName: string;
}

const RenewingTypeRadio = ({ fieldName }: RenewingTypeRadioProps) => {
  const { control } = useFormContext();

  return (
    <div>
      <h2>Type de renouvellement</h2>
      <FormField
        control={control}
        name={fieldName}
        render={({ field }) => (
          <FormItem className="w-full">
            <FormControl>
              <RadioGroup
                name={field.name}
                defaultValue={field.value}
                onValueChange={field.onChange}
                className="flex flex-row gap-16 w-full mt-1"
              >
                {[
                  {
                    value: RenewingType.SAME,
                    msg: "Même périmètre",
                  },
                  {
                    value: RenewingType.REDUCED,
                    msg: "Périmètre réduit",
                  },
                  {
                    value: RenewingType.FUSION,
                    msg: "Regroupement des périmètres",
                  },
                ].map(({ value, msg }) => {
                  const itemId = `${field.name}${value}`;

                  return (
                    <div className="flex items-center self-stretch" key={value}>
                      <RadioGroupItem
                        id={itemId}
                        value={value}
                        className="flex items-center justify-center gap-2"
                      />
                      <FormLabel htmlFor={itemId}>{msg}</FormLabel>
                    </div>
                  );
                })}
              </RadioGroup>
            </FormControl>
          </FormItem>
        )}
      />
    </div>
  );
};

export default RenewingTypeRadio;
