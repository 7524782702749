import { useQueryCandidateBasicInfo } from "@/api/candidate/candidate";
import { Button } from "@/components/atoms/Button";
import { ScrollArea } from "@/components/atoms/ScrollArea";
import { Skeleton } from "@/components/atoms/Skeleton";
import ArchiveCandidateDialog from "@/components/molecules/ArchiveCandidateDialog";
import LoadingError from "@/components/molecules/LoadingError";
import { ROUTE } from "@/constants/routes";
import { formatDate } from "@/lib/utils";
import { ArrowLeft, Ban } from "lucide-react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import CandidateStatusBadge from "../../../../molecules/Badges/CandidateStatusBadge";
import { CandidateContext } from "./candidate-context";
import CandidateTabs from "./CandidateTabs";

const Candidate = () => {
  const { id: idStr } = useParams();
  const id = Number(idStr);
  const navigate = useNavigate();
  const location = useLocation();

  const { data, isLoading } = useQueryCandidateBasicInfo(id, {
    enabled: !!id,
  });

  if (!id) navigate(ROUTE.admin.dashboard.candidates());

  const context: CandidateContext | undefined =
    data && data !== "id-not-found" ? { candidate: data } : undefined;

  return (
    <div className="flex flex-col w-full bg-white h-screen overflow-hidden">
      <div className="flex flex-col w-full border-b border-b-gray-200 px-6 pt-3 gap-2">
        <div className="flex flex-row justify-between w-full items-center">
          <div className="flex flex-row gap-4 items-center">
            <Button
              variant="ghostWithBorders"
              className="p-2 rounded-lg"
              onClick={() =>
                navigate(
                  location.state?.from || ROUTE.admin.dashboard.candidates()
                )
              }
            >
              <ArrowLeft className="w-5 h-5 text-gray-500" />
            </Button>
            {!data &&
              (isLoading ? (
                <Skeleton className="w-40 h-10 " />
              ) : (
                <LoadingError>Erreur de chargement du candidat</LoadingError>
              ))}
            {data && data === "id-not-found" && (
              <Ban className="text-red-500 text-2xl" />
            )}
            {data && data !== "id-not-found" && (
              <div className="flex flex-col justify-center">
                <div className="flex flex-row gap-2 items-center">
                  <h1>
                    {data.firstName} {data.lastName}
                  </h1>
                  <CandidateStatusBadge basicInfo={data} />
                </div>
                {data.lastCertificate && (
                  <span className="text-gray-600">
                    Fin de validité :{" "}
                    {formatDate(data.lastCertificate?.expirationDate)}
                  </span>
                )}
              </div>
            )}
          </div>
          {data && data !== "id-not-found" && (
            <ArchiveCandidateDialog
              archived={data.archived}
              candidateId={data.id}
              firstName={data.firstName}
              lastName={data.lastName}
            />
          )}
        </div>
        <CandidateTabs candidateId={id} />
      </div>
      {data &&
        (data === "id-not-found" ? (
          <div className="flex w-full h-full justify-center items-center">
            Aucun utilisateur avec cet identifiant
          </div>
        ) : (
          <ScrollArea className="h-full">
            <Outlet context={context} />
          </ScrollArea>
        ))}
    </div>
  );
};

export default Candidate;
