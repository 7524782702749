import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import AttestationCard from "./Cards/AttestationsCard/AttestationsCard";
import ConvocationCard from "./Cards/ConvocationCard";
import DelegationCard from "./Cards/DelegationCard";
import PayementCard from "./Cards/PayementCard";
import PreTestCard from "./Cards/PreTestCard";
import ReducedPriceCard from "./Cards/ReducedPriceCard";

interface PreExamDocumentProps {
  registration: CandidateRegistrationDtoClient;
}

const PreExamDocument = ({ registration }: PreExamDocumentProps) => (
  <div>
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
      <PayementCard registration={registration} />
      <ReducedPriceCard registration={registration} />
      <PreTestCard registration={registration} />
      <AttestationCard registration={registration} />
      <ConvocationCard registration={registration} />
      <DelegationCard registration={registration} />
    </div>
  </div>
);

export default PreExamDocument;
