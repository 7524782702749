import { removeNullsDeep } from "@/api/app";
import { ExtensionType } from "@/api/enums/ExtensionType";
import { RegistrationType } from "@/api/enums/RegistrationType";
import { RenewingType } from "@/api/enums/RenewingType";
import ExamDateCombobox from "@/components/molecules/ExamDateCombobox";
import { extensionPropertiesFromDto } from "@/components/molecules/RegistrationForms/ExtensionProperties/extension-properties.schema";
import ExtensionPropertiesForm from "@/components/molecules/RegistrationForms/ExtensionProperties/ExtensionPropertiesForm";
import InitialPropertiesForm from "@/components/molecules/RegistrationForms/InitialProperties/InitialPropertiesForm";
import RenewingPropertiesForm from "@/components/molecules/RegistrationForms/RenewingProperties/RenewingPropertiesForm";
import { Perimeter } from "@/lib/perimeter";
import { Form } from "@atoms/Form";
import { zodResolver } from "@hookform/resolvers/zod";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import NextStepButton from "../NextStepButtons";
import { RegisterContext } from "../RegisterContext";
import {
  RegisterStepSchema,
  registerStepSchema,
  registerStepSchemaToExamOptions,
} from "./register-step.schema";
import RegistrationTypeRadio from "./RegistrationTypeRadio";
import WarningMessage from "./WarningMessage";

const RegisterStep = () => {
  const {
    changeDraft,
    onNextStep,
    registrationDraft: draft,
    pending,
    candidateId,
  } = useOutletContext<RegisterContext>();

  const form = useForm<RegisterStepSchema>({
    resolver: zodResolver(registerStepSchema),
    defaultValues: _.merge(
      {
        type: RegistrationType.INITIAL,
        initialExamId: -1,
        renewingExamId: -1,
        extensionExamId: -1,
        initialProperties: {
          utc: 1,
          perimeter: Perimeter.empty(),
        },
        renewingProperties: {
          type: RegistrationType.RENEW,
          perimeter: Perimeter.empty(),
          renewingCertificatesIDs: [],
          renewingType: RenewingType.SAME,
        },
        extensionProperties: {
          type: RegistrationType.EXTENSION,
          utc: 1,
          certificateId: -1,
          extension: {
            extensionType: ExtensionType.MERCHANDISES,
            extendedMerchandiseClasses: [],
            extendedTransportModes: [],
          },
        },
      },
      removeNullsDeep({
        type: draft.type,
        initialExamId:
          draft.type === RegistrationType.INITIAL ? draft.examId : -1,
        renewingExamId:
          draft.type === RegistrationType.RENEW ? draft.examId : -1,
        extensionExamId:
          draft.type === RegistrationType.EXTENSION ? draft.examId : -1,
        initialProperties: draft.initialProperties,
        renewingProperties: draft.renewingProperties,
        extensionProperties: draft.extensionProperties
          ? extensionPropertiesFromDto(draft.extensionProperties)
          : undefined,
      })
    ),
  });

  const onSubmit = (data: RegisterStepSchema) =>
    changeDraft(registerStepSchemaToExamOptions(data));

  const registrationType = form.watch("type");

  return (
    <div className="flex flex-col items-start w-full max-w-4xl">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit((data) => onSubmit(data))}
          className="flex w-full flex-col space-y-8"
        >
          <div className="flex p-4 flex-col items-start gap-8 self-stretch rounded-md border-gray-200 bg-white border w-full">
            <h1>Inscription à l'examen</h1>
            <RegistrationTypeRadio />

            {registrationType === RegistrationType.INITIAL && (
              <>
                <ExamDateCombobox
                  fieldName="initialExamId"
                  registrationType={RegistrationType.INITIAL}
                />
                <InitialPropertiesForm fieldName="initialProperties" />
              </>
            )}

            {registrationType === RegistrationType.RENEW && (
              <>
                <ExamDateCombobox
                  fieldName="renewingExamId"
                  registrationType={RegistrationType.RENEW}
                />
                <RenewingPropertiesForm
                  fieldName="renewingProperties"
                  candidateId={candidateId}
                />
              </>
            )}

            {registrationType === RegistrationType.EXTENSION && (
              <>
                <ExamDateCombobox
                  fieldName="extensionExamId"
                  registrationType={RegistrationType.EXTENSION}
                />
                <ExtensionPropertiesForm
                  fieldName="extensionProperties"
                  candidateId={candidateId}
                />
              </>
            )}

            <WarningMessage />
          </div>
          <NextStepButton
            onNext={form.handleSubmit((data) => onSubmit(data))}
            onPrevious={() => onNextStep(false)}
            pending={pending}
          />
        </form>
      </Form>
    </div>
  );
};

export default RegisterStep;
