import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import { ExamStatus, ExamStatusUtil } from "@/api/enums/exam-status.enum";
import { RegistrationType } from "@/api/enums/RegistrationType";
import { keyFactory } from "@/api/keyFactory";
import { useMutationRegistrationModifyProperties } from "@/api/registration/registration";
import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/atoms/Dialog";
import { Form } from "@/components/atoms/Form";
import ExtensionPropertiesForm from "@/components/molecules/RegistrationForms/ExtensionProperties/ExtensionPropertiesForm";
import InitialPropertiesForm from "@/components/molecules/RegistrationForms/InitialProperties/InitialPropertiesForm";
import RenewingPropertiesForm from "@/components/molecules/RegistrationForms/RenewingProperties/RenewingPropertiesForm";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import {
  ModifyPropertiesSchema,
  modifyPropertiesDefaultValues,
  modifyPropertiesToDto,
} from "./modify-properties.schema";

interface ModifyPropertiesDialogProps {
  registration: CandidateRegistrationDtoClient;
}

const ModifyPropertiesDialog = ({
  registration,
}: ModifyPropertiesDialogProps) => {
  const [open, setOpen] = useState(false);
  const { mutate, isPending } = useMutationRegistrationModifyProperties();
  const queryClient = useQueryClient();

  const canModifyProperties =
    ExamStatusUtil.order(registration.exam.status) <=
      ExamStatusUtil.order(ExamStatus.QCMS_NOT_GENERATED) &&
    new Date(registration.exam.registeringEndDate) >= new Date();

  const form = useForm<ModifyPropertiesSchema>({
    defaultValues: modifyPropertiesDefaultValues(registration),
  });

  const onSubmit = (data: ModifyPropertiesSchema) =>
    mutate(
      { body: modifyPropertiesToDto(data) },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: keyFactory.registration.base(),
          });

          queryClient.invalidateQueries({
            queryKey: keyFactory.candidate.registrations(),
          });

          toast.success("Modification effectuée");
          setOpen(false);
        },
        onError: () => toast.error("Erreur lors de la modification"),
      }
    );

  if (!canModifyProperties) return false;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>Modifier mon périmètre</Button>
      </DialogTrigger>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>Modification du périmètre</DialogTitle>
          <DialogDescription hidden>
            Modification du périmètre
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit((data) => onSubmit(data))}
            className="flex w-full flex-col space-y-8"
          >
            {registration.type === RegistrationType.INITIAL && (
              <InitialPropertiesForm fieldName={"initialProperties"} />
            )}

            {registration.type === RegistrationType.EXTENSION && (
              <ExtensionPropertiesForm fieldName={"extensionProperties"} />
            )}

            {registration.type === RegistrationType.RENEW && (
              <RenewingPropertiesForm fieldName={"renewingProperties"} />
            )}

            <div className="flex flex-row items-center gap-4 w-full">
              <Button
                variant="ghostWithBorders"
                className="w-1/2"
                onClick={() => setOpen(false)}
                disabled={isPending}
                type="button"
              >
                Annuler
              </Button>
              <Button className="w-1/2" disabled={isPending}>
                {isPending ? "En cours..." : "Modifier"}
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default ModifyPropertiesDialog;
