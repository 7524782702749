import { Tabs, TabsList, TabsTrigger } from "@/components/atoms/Tabs";
import { ROUTE } from "@/constants/routes";
import { useLocation, useNavigate } from "react-router-dom";
import { CandidateTab } from "./candidate-tab.enum";

interface CandidateTabsProps {
  candidateId: number;
}

const tabsTitles = {
  [CandidateTab.OnGoingSession]: "Session en cours",
  [CandidateTab.Results]: "Résultats",
  [CandidateTab.Documents]: "Documents",
  [CandidateTab.PersonalInfo]: "Informations personnelles",
  [CandidateTab.Certificates]: "Certificats",
};

const CandidateTabs = ({ candidateId }: CandidateTabsProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <Tabs value={pathname}>
      <TabsList variant="transparent" className="pb-0 mb-0">
        {[
          {
            tab: CandidateTab.OnGoingSession,
            route: ROUTE.admin.dashboard.candidate.onGoingSession(candidateId),
          },
          {
            tab: CandidateTab.Results,
            route: ROUTE.admin.dashboard.candidate.results(candidateId),
          },
          {
            tab: CandidateTab.Documents,
            route: ROUTE.admin.dashboard.candidate.documents(candidateId),
          },
          {
            tab: CandidateTab.PersonalInfo,
            route:
              ROUTE.admin.dashboard.candidate.personalInformation(candidateId),
          },
          {
            tab: CandidateTab.Certificates,
            route: ROUTE.admin.dashboard.candidate.certificates(candidateId),
          },
        ].map(({ route, tab }) => (
          <TabsTrigger
            variant="underlineBlue"
            value={route}
            onClick={() => navigate(route)}
            key={tab}
          >
            {tabsTitles[tab]}
          </TabsTrigger>
        ))}
      </TabsList>
    </Tabs>
  );
};

export default CandidateTabs;
