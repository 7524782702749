import { RenewingType } from "@/api/enums/RenewingType";
import { Perimeter } from "@/lib/perimeter";
import { z } from "zod";
import { perimeterSchema } from "../../PerimeterForm/PerimeterFormSchema";

const renewingPropertiesSchema = z
  .object({
    renewingCertificatesIDs: z
      .number()
      .array()
      .superRefine((ids, ctx) => {
        if (ids.length === 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Veuillez choisir au moins un certificat",
          });
        }
      }),
    perimeter: perimeterSchema,
    renewingType: z.nativeEnum(RenewingType),
  })
  .superRefine(({ renewingCertificatesIDs, renewingType }, ctx) => {
    if (renewingCertificatesIDs.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Veuillez choisir au moins 1 certificat.",
        path: ["renewingCertificatesIDs"],
      });

      return;
    }

    if (
      [RenewingType.REDUCED, RenewingType.SAME].includes(renewingType) &&
      renewingCertificatesIDs.length > 1
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Veuillez choisir 1 certificat maximum.",
        path: ["renewingCertificatesIDs"],
      });
    }

    if (
      renewingType === RenewingType.FUSION &&
      renewingCertificatesIDs.length < 2
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Veuillez choisir au moins 2 certificats.",
        path: ["renewingCertificatesIDs"],
      });
    }
  });

type RenewingPropertiesSchema = z.infer<typeof renewingPropertiesSchema>;

const renewingPropertiesDefaultValues: RenewingPropertiesSchema = {
  perimeter: Perimeter.empty(),
  renewingCertificatesIDs: [],
  renewingType: RenewingType.SAME,
};

export { renewingPropertiesDefaultValues, renewingPropertiesSchema };
export type { RenewingPropertiesSchema };
