import { keyFactory } from "@/api/keyFactory";
import { useUploadMutation } from "@/api/question/question";
import { Button } from "@/components/atoms/Button";
import { Checkbox } from "@/components/atoms/CheckBox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/atoms/Dialog";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/atoms/Form";
import DropzoneMolecule from "@/components/molecules/Dropzone";
import ErrorDisplay from "@/components/molecules/ErrorDisplay";
import { emptyFile, file } from "@/constants/zodTypes";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { Plus } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const schema = z.object({
  file: file([
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ]),
  eraseOldQuestions: z.boolean(),
});
type Schema = z.infer<typeof schema>;

const ImportDialog = () => {
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const queryClient = useQueryClient();
  const uploadMutation = useUploadMutation();

  const form = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: { file: emptyFile(), eraseOldQuestions: false },
  });

  const onSubmit = (data: Schema) => {
    uploadMutation.mutate(
      { file: data.file as File, eraseOldQuestions: data.eraseOldQuestions },
      {
        onSuccess: (uploadedQuestionsCount) => {
          setErrorMessage("");

          queryClient.invalidateQueries({
            queryKey: keyFactory.question.base(),
          });

          toast.success("Import effectué", {
            description: `${uploadedQuestionsCount} nouvelles questions ont été importées.`,
          });

          setOpen(false);
          form.reset();
        },
        onError: (err) => {
          setErrorMessage(err.message);
        },
      }
    );
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button className="gap-1 px-3">
          <Plus />
          Importer
        </Button>
      </DialogTrigger>
      <DialogContent
        onClick={(e) => e.stopPropagation()}
        className="flex flex-col gap-4 p-4"
      >
        <DialogHeader>
          <DialogTitle>Importer des modifications</DialogTitle>
          <DialogDescription hidden={true}>
            Importer des modifications
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-4"
          >
            <FormField
              name="file"
              control={form.control}
              render={() => (
                <FormItem className="w-full">
                  <DropzoneMolecule
                    id="file"
                    accept={{
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                        [".xlsx"],
                    }}
                  >
                    <span>
                      <span className="text-brand-700">Cliquez</span> pour
                      déposer votre base de questions
                    </span>
                  </DropzoneMolecule>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              name="eraseOldQuestions"
              control={form.control}
              render={({ field }) => (
                <FormItem className="flex flex-row items-start space-x-3 space-y-0 p-4">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel>Import total</FormLabel>
                    <FormDescription>
                      Les questions absentes de l'import seront supprimées.
                    </FormDescription>
                  </div>
                </FormItem>
              )}
            />

            {errorMessage && <ErrorDisplay msg={errorMessage} />}
            <div className="flex flex-row gap-4">
              <Button
                variant="red"
                className="w-full"
                onClick={() => setOpen(false)}
                type="button"
                disabled={uploadMutation.isPending}
              >
                Annuler
              </Button>
              <Button
                variant="green"
                className="w-full"
                type="submit"
                disabled={uploadMutation.isPending}
              >
                {uploadMutation.isPending ? (
                  <span className="animate-pulse">Import en cours...</span>
                ) : (
                  "Importer"
                )}
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default ImportDialog;
