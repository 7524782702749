import {
  useMutationAuthExamLogin,
  useMutationAuthLogin,
} from "@/api/auth/auth";
import { Role } from "@/api/enums/Role";
import { ConnectionSchema } from "@/components/templates/ConnectPage/ConnectionForm";
import ConnectionPage from "@/components/templates/ConnectPage/ConnectPage";
import { ROUTE } from "@/constants/routes";
import { socket } from "@/hooks/socket";
import { useSocketStore } from "@/hooks/useSocketStore";
import { useEffect, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { toast } from "sonner";
import { ExamContext } from "../exam.context";

const Login = () => {
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");
  const { connected } = useSocketStore();
  const [webSocketPending, setWebSocketPending] = useState(false);
  const { isTest } = useOutletContext<ExamContext>();

  const onError = (error: any) =>
    setErrorMsg(
      error.response?.data?.message ??
        "Une erreur est survenue lors de la connexion."
    );

  const onSuccess = () => {
    setErrorMsg("");
    if (!isTest) socket.io.opts.query = { role: Role.CANDIDATE };
    socket.connect();
    setWebSocketPending(true);
  };

  const loginMutation = useMutationAuthLogin();
  const examLoginMutation = useMutationAuthExamLogin();

  // if it is a test, do a simple login, just
  // to train user
  const onSubmit = ({ email, key }: ConnectionSchema) =>
    (isTest ? loginMutation : examLoginMutation).mutate(
      {
        username: email,
        key,
      },
      { onSuccess, onError }
    );

  useEffect(() => {
    console.log(`NTM ${connected}`); // TODO remove console.log
  }, [connected]);

  // redirect user on websocket connexion
  useEffect(() => {
    if (!connected) return;

    toast.success("Connexion réussie.");
    setWebSocketPending(false);
    navigate({
      pathname: ROUTE.exam.questions(),
      search: createSearchParams({ test: String(isTest) }).toString(),
    });
  }, [connected, navigate, isTest]);

  return (
    <ConnectionPage
      title="Se connecter à un examen"
      onSubmit={onSubmit}
      pending={examLoginMutation.isPending || webSocketPending}
      errorMsg={errorMsg}
    />
  );
};

export default Login;
