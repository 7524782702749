import { Button } from "@/components/atoms/Button";
import { ROUTE } from "@/constants/routes";
import { Search } from "lucide-react";
import { NavLink } from "react-router-dom";

const NoExams = () => {
  return (
    <div className="flex py-8 flex-col gap-4 self-stretch items-center rounded-lg bg-gray-100">
      <Search className="w-6 h-6 text-gray-400" />
      <span className="text-gray-800">Vous n'avez pas réalisé d'examen</span>
      <NavLink to={ROUTE.candidate.dashboard.exams.register().root()}>
        <Button className="flex px-3 py-2 mt-3 justify-center rounded-lg border border-brand-700 bg-brand-600 font-semibold text-sm">
          S'inscrire à une session d'examen
        </Button>
      </NavLink>
    </div>
  );
};

export default NoExams;
