import { ExamDtoClient } from "@/api/dto/exam.dto";
import { ExamType } from "@/api/enums/ExamType";
import { RegistrationType } from "@/api/enums/RegistrationType";
import { useQueryExamAll } from "@/api/exam/exam";
import { ComboboxField } from "@/components/molecules/Combobox/Combobox";
import { formatDate } from "@/lib/utils";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@atoms/Form";
import { useFormContext } from "react-hook-form";

const filterByRegistrationType =
  (registrationType: RegistrationType) => (exam: ExamDtoClient) =>
    // still time to register
    new Date(exam.registeringEndDate) >= new Date() &&
    // coherent exam type
    ((exam.type === ExamType.INITIAL &&
      [RegistrationType.EXTENSION, RegistrationType.INITIAL].includes(
        registrationType
      )) ||
      (exam.type === ExamType.RENEW &&
        registrationType === RegistrationType.RENEW));

const defaultLabel = (exam: ExamDtoClient) =>
  `${formatDate(exam.date)} (Date limite d'inscription le ${formatDate(
    exam.registeringEndDate
  )} )`;

interface ExamDateComboboxProps {
  fieldName: string;
  registrationType?: RegistrationType;
  forbiddenExamIds?: Set<number>;
  filter?: (exam: ExamDtoClient) => boolean;
  title?: string;
  label?: (exam: ExamDtoClient) => string;
}

const ExamDateCombobox = ({
  fieldName,
  registrationType,
  forbiddenExamIds = new Set(),
  filter,
  title = "Date de passage de l'examen",
  label = defaultLabel,
}: ExamDateComboboxProps) => {
  const { control } = useFormContext();

  const { data, isLoading, isError } = useQueryExamAll();

  return (
    <FormField
      control={control}
      name={fieldName}
      render={() => (
        <FormItem className="w-full">
          <FormLabel>
            <h2>{title}</h2>
          </FormLabel>
          <FormControl>
            <ComboboxField
              displaySearchBox={true}
              options={
                data
                  ?.filter(filter ?? (() => true))
                  .filter(
                    registrationType
                      ? filterByRegistrationType(registrationType)
                      : () => true
                  )
                  .sort(
                    (exam0, exam1) =>
                      new Date(exam0.date).getTime() -
                      new Date(exam1.date).getTime()
                  )
                  .map((exam) => {
                    return {
                      value: exam.id,
                      disabled: forbiddenExamIds.has(exam.id),
                      label: label(exam),
                    };
                  }) ??
                (isError
                  ? [
                      {
                        label: "Erreur de chargement des dates d'examen",
                        value: undefined,
                        disabled: true,
                      },
                    ]
                  : isLoading
                  ? [
                      {
                        label: "Chargement...",
                        value: undefined,
                        disabled: true,
                      },
                    ]
                  : [])
              }
              placeholder={"-"}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default ExamDateCombobox;
