import { Alert, AlertDescription, AlertTitle } from "@/components/atoms/Alert";
import { AlertCircle } from "lucide-react";

const TestBanner = () => (
  <Alert variant="orange">
    <AlertCircle className="h-5 w-5 stroke-white" />
    <AlertTitle className="ml-2">
      Ceci est l'interface TEST de l'épreuve QCM : vous pouvez en tester toutes
      les fonctionnalités.
    </AlertTitle>
    <AlertDescription className="font-normal ml-2">
      Les questions proposées ne correspondent pas à votre périmètre final
      d'examen et sont communes à tous les candidats. Elles ne sont pas
      utilisées pour un examen.
    </AlertDescription>
  </Alert>
);

export default TestBanner;
