import { utc } from "@/constants/zodTypes";
import { Perimeter } from "@/lib/perimeter";
import { z } from "zod";
import { perimeterSchema } from "../../PerimeterForm/PerimeterFormSchema";

const initialPropertiesSchema = z.object({
  utc: utc(),
  perimeter: perimeterSchema,
});

type InitialPropertiesSchema = z.infer<typeof initialPropertiesSchema>;

const initialPropertiesDefaultValues: InitialPropertiesSchema = {
  utc: 1,
  perimeter: Perimeter.empty(),
};

export { initialPropertiesDefaultValues, initialPropertiesSchema };
export type { InitialPropertiesSchema };
