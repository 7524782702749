import { ExamResultStatus } from "@/api/enums/exam-result-status.enum";
import { ExamType } from "@/api/enums/ExamType";
import { RegistrationType } from "@/api/enums/RegistrationType";
import { ExamDetailedDtoClient } from "@/api/exam/dto/exam-detailed.dto";
import { AdminRegistrationDtoClient } from "@/api/registration-dto/dto/admin-registration.dto";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/atoms/Popover";
import ReportDialog from "@/components/molecules/ReportDialog";
import { Ellipsis, GraduationCap } from "lucide-react";
import { useState } from "react";
import { SessionTab } from "../../../session-tab.enum";
import GenerateCertificatesDialog from "../../../SessionButtons/GenerateCertificatesDialog";
import AdditionalTimeDialog from "./AdditionalTimeDialog";
import ModifyGradeDialog from "./ModifyGradeDialog/ModifyGradeDialog";

interface ActionsPopOverProps {
  exam: ExamDetailedDtoClient;
  registration: AdminRegistrationDtoClient;
  tab: SessionTab;
}

const ActionsPopOver = ({ exam, registration, tab }: ActionsPopOverProps) => {
  const { candidate, id, additionalTimeMinutes } = registration;
  const [certificatesDialogOpen, setCertificatesDialogOpen] = useState(false);

  return (
    <>
      <Popover>
        <PopoverTrigger asChild onClick={(e) => e.stopPropagation()}>
          <Ellipsis className="w-5 h-5" />
        </PopoverTrigger>
        <PopoverContent
          className="flex flex-col p-0 w-fit"
          onClick={(e) => e.stopPropagation()}
        >
          {tab === SessionTab.Candidates && (
            <>
              <AdditionalTimeDialog
                registrationId={id}
                candidateName={`${candidate.lastName} ${candidate.firstName}`}
                currentAdditionalTimeMinutes={additionalTimeMinutes}
              />
              <ReportDialog
                candidateId={candidate.id}
                registrationId={id}
                registrationType={
                  // extension and initial registrations use
                  // the same exams
                  exam.type === ExamType.INITIAL
                    ? RegistrationType.INITIAL
                    : RegistrationType.RENEW
                }
                currentExamId={exam.id}
              />
            </>
          )}
          {tab === SessionTab.Results && (
            <>
              <ModifyGradeDialog registration={registration} />
              {/* don't propose to generate certificate
               if the exam was not successfully passed */}
              {registration.examResult?.status === ExamResultStatus.PASSED &&
                (exam.type === ExamType.RENEW ||
                  registration.edcResult?.status ===
                    ExamResultStatus.PASSED) && (
                  <>
                    <button
                      className="flex flex-row items-center gap-2 p-2"
                      onClick={() => setCertificatesDialogOpen(true)}
                    >
                      <GraduationCap className="w-4 h-4" />
                      Générer le certificat
                    </button>
                    <GenerateCertificatesDialog
                      examId={exam.id}
                      open={certificatesDialogOpen}
                      setOpen={setCertificatesDialogOpen}
                      registrationId={registration.id}
                    />
                  </>
                )}
            </>
          )}
        </PopoverContent>
      </Popover>
    </>
  );
};

export default ActionsPopOver;
