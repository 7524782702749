import { ExamResultStatus } from "@/api/enums/exam-result-status.enum";
import { ExamType } from "@/api/enums/ExamType";
import { CandidateExamResultDtoClient } from "@/api/exam-result/dto/candidate-exam-result-paginated.dto";
import { Badge } from "@/components/atoms/Badge";
import GradeDisplay from "@/components/molecules/GradeDisplay";
import { Perimeter } from "@/lib/perimeter";
import { createColumnHelper } from "@tanstack/react-table";
import WaitingBadge from "./WaitingBadge";

const columnHelper = createColumnHelper<CandidateExamResultDtoClient>();

export const generateColumns = () => [
  columnHelper.accessor("registration.number", {
    id: "registration.number",
    header: "N°",
    cell: (info) => <span>{info.getValue()}</span>,
  }),

  columnHelper.accessor("registration.perimeter", {
    id: "registration.perimeter",
    header: "Pér.",
    cell: (info) => (
      <Badge variant="white">{Perimeter.toCode(info.getValue())}</Badge>
    ),
  }),

  columnHelper.accessor("registration", {
    id: "registration",
    header: "Ext.",
    cell: (info) => {
      const { extensionPerimeter } = info.getValue();

      if (!extensionPerimeter) return false;
      return (
        <Badge variant="white">{Perimeter.toCode(extensionPerimeter)}</Badge>
      );
    },
  }),

  columnHelper.accessor((x) => x, {
    id: "status",
    header: "Statut",
    cell: (info) => {
      const { missing, result } = info.getValue();

      if (missing) return <Badge variant="white">Absent</Badge>;
      if (!result || result.status === ExamResultStatus.UNAVAILABLE)
        return <Badge variant="white">En attente</Badge>;
      if (result.status === ExamResultStatus.PASSED)
        return <Badge variant="green">Admis</Badge>;
      if (result.status === ExamResultStatus.FAILED)
        return <Badge variant="red">Ajourné</Badge>;
    },
  }),

  columnHelper.accessor("result", {
    id: "qcmResult",
    header: "Note QCM",
    cell: (info) => {
      const result = info.getValue();
      if (!result) return <WaitingBadge />;

      return (
        <GradeDisplay
          grade={result.grade}
          gradeTotal={result.gradeTotal}
          passed={result.status === ExamResultStatus.PASSED}
        />
      );
    },
  }),

  columnHelper.accessor("result", {
    id: "qcmResultAverage",
    header: "Résultat QCM",
    cell: (info) => {
      const result = info.getValue();
      if (!result) return <WaitingBadge />;

      if (!result.gradeTotal) return false;

      return (
        <GradeDisplay
          grade={((result.grade * 20) / result.gradeTotal).toFixed(2)}
          gradeTotal={20}
          passed={result.status === ExamResultStatus.PASSED}
        />
      );
    },
  }),

  columnHelper.accessor(({ edcResult, exam }) => ({ edcResult, exam }), {
    id: "edcAverage",
    header: "Note EDC",
    cell: (info) => {
      const { edcResult, exam } = info.getValue();

      // renewing exams don't include an edc
      if (exam.type === ExamType.RENEW) return false;
      if (!edcResult) return <WaitingBadge />;

      const grade = edcResult.notes.reduce((acc, g) => {
        return acc + g;
      }, 0);

      const gradeTotal = edcResult.notesTotal.reduce((acc, g) => {
        return acc + g;
      }, 0);

      return (
        <GradeDisplay
          grade={grade}
          gradeTotal={gradeTotal}
          passed={edcResult.status === ExamResultStatus.PASSED}
        />
      );
    },
  }),

  ...[0, 1].map((i) =>
    columnHelper.accessor(({ edcResult, exam }) => ({ edcResult, exam }), {
      id: `edcSpecialty${i}`,
      header: `Spécialité ${i + 1}`,
      cell: (info) => {
        const { edcResult, exam } = info.getValue();

        // renewing exams don't include an edc
        if (exam.type === ExamType.RENEW) return false;
        if (!edcResult) return <WaitingBadge />;
        if (edcResult.notes.length < 2) return false;

        return (
          <GradeDisplay
            grade={edcResult.notes[i]}
            gradeTotal={edcResult.notesTotal[i]}
            passed={edcResult.status === ExamResultStatus.PASSED}
          />
        );
      },
    })
  ),
];
