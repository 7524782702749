// paginated questions

import { ENDPOINT } from "@/constants/endpoints";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { PaginationResponseDto } from "../dto/pagination.dto";
import { keyFactory } from "../keyFactory";
import {
  FiltersDtoClient,
  QuestionBasicDtoClient,
  QuestionBasicPaginationRequestDto,
  QuestionDtoClient,
} from "./dto/question.dto";

const getQuestions = async (params: QuestionBasicPaginationRequestDto) => {
  return await axios
    .get<PaginationResponseDto<QuestionBasicDtoClient>>(
      ENDPOINT.question.getAllPaginated(),
      {
        params,
      }
    )
    .then(({ data }) => data);
};

export const useQuestionsQuery = (
  params: QuestionBasicPaginationRequestDto,
  props?: Partial<
    UseQueryOptions<PaginationResponseDto<QuestionBasicDtoClient>>
  >
) => {
  return useQuery({
    queryKey: keyFactory.question.allPaginated(params),
    queryFn: () => getQuestions(params),
    staleTime: Infinity,
    gcTime: 1000 * 60,
    ...props,
  });
};

// filters

const getFilters = async () => {
  return await axios
    .get<FiltersDtoClient>(ENDPOINT.question.getFilters())
    .then(({ data }) => data);
};

export const useFiltersQuery = (
  props?: Partial<UseQueryOptions<FiltersDtoClient>>
) => {
  return useQuery({
    queryKey: keyFactory.question.filters(),
    queryFn: getFilters,
    staleTime: Infinity,
    gcTime: 1000 * 60,
    ...props,
  });
};

// by id

const getQuestionById = async (questionId: number) => {
  return await axios
    .get<QuestionDtoClient>(ENDPOINT.question.getById(questionId))
    .then(({ data }) => data);
};

export const useQuestionById = (
  questionId: number,
  props?: Partial<UseQueryOptions<QuestionDtoClient>>
) => {
  return useQuery({
    queryKey: keyFactory.question.byId(questionId),
    queryFn: () => getQuestionById(questionId),
    staleTime: Infinity,
    gcTime: 1000 * 60,
    ...props,
  });
};

// upload questions

type UploadDto = {
  file: File;
  eraseOldQuestions: boolean;
};

const upload = async ({ eraseOldQuestions, file }: UploadDto) => {
  if (
    file.type !==
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  )
    throw new Error("Le fichier doit être un fichier xlsx");

  const formData = new FormData();
  formData.append("file", file);

  return await axios
    .post<number>(ENDPOINT.question.postUpload(eraseOldQuestions), formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res.data);
};

export const useUploadMutation = (
  props?: Partial<UseMutationOptions<number, AxiosError, UploadDto>>
) => {
  return useMutation({
    mutationFn: upload,
    ...props,
  });
};

// search

const search = async (number: number) =>
  await axios
    .get<QuestionBasicDtoClient | null>(ENDPOINT.question.getSearch(number))
    .then((res) => res.data);

export const useQueryQuestionSearch = (
  number: number,
  props?: Partial<UseQueryOptions<QuestionBasicDtoClient | null, AxiosError>>
) =>
  useQuery({
    queryKey: keyFactory.question.search(number),
    queryFn: () => search(number),
    staleTime: 1000 * 60,
    gcTime: 1000 * 60,
    ...props,
  });
