import { ExamOptionsDto } from "@/api/draft/dto/exam-options.dto";
import { RegistrationType } from "@/api/enums/RegistrationType";
import {
  extensionPropertiesSchema,
  extensionPropertiesToDto,
} from "@/components/molecules/RegistrationForms/ExtensionProperties/extension-properties.schema";
import { initialPropertiesSchema } from "@/components/molecules/RegistrationForms/InitialProperties/initial-properties.schema";
import { renewingPropertiesSchema } from "@/components/molecules/RegistrationForms/RenewingProperties/renewing-properties.schema";
import { id } from "@/constants/zodTypes";
import { z } from "zod";

const registerStepSchema = z.discriminatedUnion("type", [
  // initial
  z.object({
    type: z.literal(RegistrationType.INITIAL),
    initialExamId: id("Veuillez choisir un examen."),
    initialProperties: initialPropertiesSchema,
  }),
  // extension
  z.object({
    type: z.literal(RegistrationType.EXTENSION),
    extensionExamId: id("Veuillez choisir un examen."),
    extensionProperties: extensionPropertiesSchema,
  }),
  // renewal
  z.object({
    type: z.literal(RegistrationType.RENEW),
    renewingExamId: id("Veuillez choisir un examen."),
    renewingProperties: renewingPropertiesSchema,
  }),
]);

type RegisterStepSchema = z.infer<typeof registerStepSchema>;

const registerStepSchemaToExamOptions = (
  data: RegisterStepSchema
): ExamOptionsDto => ({
  ...(data.type === RegistrationType.INITIAL && {
    examId: data.initialExamId,
    initialProperties: data.initialProperties,
  }),
  ...(data.type === RegistrationType.EXTENSION && {
    examId: data.extensionExamId,
    extensionProperties: extensionPropertiesToDto(data.extensionProperties),
  }),
  ...(data.type === RegistrationType.RENEW && {
    examId: data.renewingExamId,
    renewingProperties: data.renewingProperties,
  }),
  type: data.type,
});

export { registerStepSchema, registerStepSchemaToExamOptions };
export type { RegisterStepSchema };
