import AdminDashboardAdministrationAdmins from "@/components/pages/Admin/Dashboard/Administration/Admins/Admins.tsx";
import AdminDashboardAdministrationAdmissionNotes from "@/components/pages/Admin/Dashboard/Administration/AdmissionNotes/AdmissionNotes.tsx";
import AdminDashboardAdministrationArchivedCandidates from "@/components/pages/Admin/Dashboard/Administration/ArchivedCandidates/ArchivedCandidates.tsx";
import AdminDashboardAdministrationArchivedExams from "@/components/pages/Admin/Dashboard/Administration/ArchivedExams/ArchivedExams.tsx";
import AdminDashboardAdministrationExports from "@/components/pages/Admin/Dashboard/Administration/Exports/Exports.tsx";
import AdminDashboardAdministrationPrices from "@/components/pages/Admin/Dashboard/Administration/Prices/Prices.tsx";
import AdminDashboardAdministrationQCMParameters from "@/components/pages/Admin/Dashboard/Administration/QCMParameters/QCMParameters.tsx";
import AdminDashboardForeignCertificates from "@/components/pages/Admin/Dashboard/ForeignCertificates/ForeignCertificates.tsx";
import AdminDashboardLiveSessions from "@/components/pages/Admin/Dashboard/Live/LiveSessions/LiveSessions.tsx";
import AdminDashboardPayements from "@/components/pages/Admin/Dashboard/Payements/Payements.tsx";
import AdminDashboardQCM from "@/components/pages/Admin/Dashboard/Qcm/Qcm.tsx";
import AdminDashboardQCMS from "@/components/pages/Admin/Dashboard/Qcms/Qcms.tsx";
import AdminDashboardRegistrations from "@/components/pages/Admin/Dashboard/Registrations/Registrations.tsx";
import AdminDashboardSessionCandidates from "@/components/pages/Admin/Dashboard/Session/Candidates/Candidates.tsx";
import AdminDashboardSessionInformation from "@/components/pages/Admin/Dashboard/Session/Information/Information.tsx";
import AdminDashboardSession from "@/components/pages/Admin/Dashboard/Session/Session.tsx";
import AdminDashboardSessionCreation from "@/components/pages/Admin/Dashboard/SessionCreation/SessionCreation.tsx";
import CandidateExamsRegisterCertificate from "@/components/pages/Candidate/Dashboard/Exams/Register/Certificate/Certificate.tsx";
import CandidateExamsRegisterPayement from "@/components/pages/Candidate/Dashboard/Exams/Register/Payement/Payement.tsx";
import CandidateExamsRegisterPricing from "@/components/pages/Candidate/Dashboard/Exams/Register/Pricing/Pricing.tsx";
import CompanyCandidates from "@/components/pages/Company/Dashboard/Candidates/Candidates.tsx";
import CompanyInformation from "@/components/pages/Company/Dashboard/Information/Information.tsx";
import AdminDashboardAdministration from "@pages/Admin/Dashboard/Administration/Administration.tsx";
import AdminDashboardAdministrationFormationInstitutes from "@pages/Admin/Dashboard/Administration/FormationInstitutes/FormationInstitutes.tsx";
import AdminDashboardCandidate from "@pages/Admin/Dashboard/Candidate/Candidate.tsx";
import AdminDashboardCandidateCertificates from "@pages/Admin/Dashboard/Candidate/Certificates/Certificates.tsx";
import AdminDashboardCandidateDocuments from "@pages/Admin/Dashboard/Candidate/Documents/Documents.tsx";
import AdminDashboardCandidateOnGoingSession from "@pages/Admin/Dashboard/Candidate/OnGoingSession/OnGoingSession.tsx";
import AdminDashboardCandidatePersonalInformation from "@pages/Admin/Dashboard/Candidate/PersonalInformation/PersonalInformation.tsx";
import AdminDashboardCandidateResults from "@pages/Admin/Dashboard/Candidate/Results/Results.tsx";
import AdminDashboardCandidates from "@pages/Admin/Dashboard/Candidates/Candidates.tsx";
import AdminDashboardCompanies from "@pages/Admin/Dashboard/Companies/Companies.tsx";
import AdminDashboardCompany from "@pages/Admin/Dashboard/Company/Company.tsx";
import AdminDashboard from "@pages/Admin/Dashboard/Dashboard.tsx";
import AdminDashboardQuestion from "@pages/Admin/Dashboard/Question/Question.tsx";
import AdminDashboardQuestions from "@pages/Admin/Dashboard/Questions/Questions.tsx";
import AdminDashboardSessions from "@pages/Admin/Dashboard/Sessions/Sessions.tsx";
import AdminLogin from "@pages/Admin/Login/Login.tsx";
import CandidateAccountCreation from "@pages/Candidate/AccountCreation/AccountCreation.tsx";
import CandidateDashboard from "@pages/Candidate/Dashboard/DashBoard.tsx";
import CandidateExams from "@pages/Candidate/Dashboard/Exams/Exams.tsx";
import CandidateExamsHome from "@pages/Candidate/Dashboard/Exams/Home/Home.tsx";
import CandidateExamsRegisterAcknowledgment from "@pages/Candidate/Dashboard/Exams/Register/Acknowledgment/Acknowledgement.tsx";
import CandidateExamsRegisterCheckInfo from "@pages/Candidate/Dashboard/Exams/Register/CheckInfo/CheckInfo.tsx";
import Register from "@pages/Candidate/Dashboard/Exams/Register/Register.tsx";
import CandidateExamsRegisterRegisterStep from "@pages/Candidate/Dashboard/Exams/Register/RegisterStep/RegisterStep.tsx";
import CandidateProfile from "@pages/Candidate/Dashboard/Profile/Profile.tsx";
import CompanyAccountCreation from "@pages/Company/AccountCreation.tsx";
import CompanyDashboard from "@pages/Company/Dashboard/Dashboard.tsx";
import CompanyDelegations from "@pages/Company/Dashboard/Delegations/Delegations.tsx";
import ConnectionHome from "@pages/ConnectionHome/ConnectionHome.tsx";
import ForgottenEmail from "@pages/ForgottenCredentials/ForgottenEmail.tsx";
import ForgottenPassword from "@pages/ForgottenCredentials/ForgottenPassword.tsx";
import PasswordChange from "@pages/ForgottenCredentials/PasswordChange.tsx";
import { Navigate, RouteObject } from "react-router-dom";
import "unfonts.css";
import Live from "./components/pages/Admin/Dashboard/Live/Live.tsx";
import LiveSession from "./components/pages/Admin/Dashboard/Live/LiveSession/LiveSession.tsx";
import Exam from "./components/pages/Exam/Exam.tsx";
import ExamDone from "./components/pages/Exam/ExamDone/ExamDone.tsx";
import ExamLogin from "./components/pages/Exam/Login/Login.tsx";
import ExamQuestions from "./components/pages/Exam/Questions/Questions.tsx";
import NotFound from "./components/pages/NotFound.tsx";
import SeedData from "./components/pages/SeedData.tsx";
import Welcome from "./components/pages/Welcome.tsx";
import { ROUTE } from "./constants/routes.ts";
import "./styles/index.css";

const registerRoute = (path: string): RouteObject => {
  const tree: RouteObject = {
    path,
    element: <Register />,
    children: [
      {
        path: "",
        element: <CandidateExamsRegisterCheckInfo />,
      },

      {
        path: "acknowledgment",
        element: <CandidateExamsRegisterAcknowledgment />,
      },

      {
        path: "certificate",
        element: <CandidateExamsRegisterCertificate />,
      },

      {
        path: "pricing",
        element: <CandidateExamsRegisterPricing />,
      },

      {
        path: "payement",
        element: <CandidateExamsRegisterPayement />,
      },
      {
        path: "register-step",
        element: <CandidateExamsRegisterRegisterStep />,
      },
    ],
  };

  return tree;
};

export const routesTree = (): RouteObject[] => {
  const tree: RouteObject[] = [
    {
      path: "/home",
      element: <ConnectionHome />,
    },
    {
      path: "/admin",
      children: [
        {
          index: true,
          element: <Navigate to={"/admin/dashboard"} replace />,
        },
        {
          path: "login",
          element: <AdminLogin />,
        },
        {
          path: "dashboard",
          element: <AdminDashboard />,
          children: [
            {
              index: true,
              element: <Navigate to={"/admin/dashboard/candidates"} replace />,
            },
            {
              path: "candidates",
              element: <AdminDashboardCandidates />,
            },
            {
              path: "foreign-certificates",
              element: <AdminDashboardForeignCertificates />,
            },
            {
              path: "candidate/:id",
              element: <AdminDashboardCandidate />,
              children: [
                {
                  path: "",
                  element: <AdminDashboardCandidateOnGoingSession />,
                },
                {
                  path: "results",
                  element: <AdminDashboardCandidateResults />,
                },
                {
                  path: "documents",
                  element: <AdminDashboardCandidateDocuments />,
                },
                {
                  path: "personal-information",
                  element: <AdminDashboardCandidatePersonalInformation />,
                },
                {
                  path: "certificates",
                  element: <AdminDashboardCandidateCertificates />,
                },
                registerRoute("register"),
              ],
            },
            {
              path: "sessions",
              element: <AdminDashboardSessions />,
            },
            {
              path: "session-creation",
              element: <AdminDashboardSessionCreation />,
            },
            {
              path: "session/:id",
              element: <AdminDashboardSession />,
              children: [
                {
                  path: "",
                  element: <AdminDashboardSessionCandidates />,
                },
                {
                  path: "information",
                  element: <AdminDashboardSessionInformation />,
                },
              ],
            },
            {
              path: "companies",
              element: <AdminDashboardCompanies />,
            },
            {
              path: "company/:id",
              element: <AdminDashboardCompany />,
            },
            {
              path: "questions",
              element: <AdminDashboardQuestions />,
            },
            {
              path: "question/:id",
              element: <AdminDashboardQuestion />,
            },
            {
              path: "administration",
              element: <AdminDashboardAdministration />,
              children: [
                {
                  index: true,
                  element: (
                    <Navigate
                      to={ROUTE.admin.dashboard.administration.formationInstitutes()}
                      replace
                    />
                  ),
                },
                {
                  path: "formation-institutes",
                  element: <AdminDashboardAdministrationFormationInstitutes />,
                },
                {
                  path: "prices",
                  element: <AdminDashboardAdministrationPrices />,
                },
                {
                  path: "admission-notes",
                  element: <AdminDashboardAdministrationAdmissionNotes />,
                },
                {
                  path: "qcm-parameters",
                  element: <AdminDashboardAdministrationQCMParameters />,
                },
                {
                  path: "exports",
                  element: <AdminDashboardAdministrationExports />,
                },
                {
                  path: "admins",
                  element: <AdminDashboardAdministrationAdmins />,
                },
                {
                  path: "archived-exams",
                  element: <AdminDashboardAdministrationArchivedExams />,
                },
                {
                  path: "archived-candidates",
                  element: <AdminDashboardAdministrationArchivedCandidates />,
                },
              ],
            },
            {
              path: "registrations",
              element: <AdminDashboardRegistrations />,
            },
            {
              path: "payements",
              element: <AdminDashboardPayements />,
            },
            {
              path: "qcms",
              element: <AdminDashboardQCMS />,
            },
            {
              path: "qcm/:date",
              element: <AdminDashboardQCM />,
            },
            {
              path: "live",
              element: <Live />,
              children: [
                {
                  path: "sessions",
                  element: <AdminDashboardLiveSessions />,
                },
                {
                  path: "session/:examId",
                  element: <LiveSession />,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "/candidate",
      children: [
        {
          index: true,
          element: <Navigate to={"/candidate/dashboard"} replace />,
        },
        {
          path: "account-creation",
          element: <CandidateAccountCreation />,
        },
        {
          path: "dashboard",
          element: <CandidateDashboard />,
          children: [
            {
              index: true,
              element: <Navigate to={"/candidate/dashboard/exams"} replace />,
            },
            {
              path: "profile",
              element: <CandidateProfile />,
            },
            {
              path: "exams",
              element: <CandidateExams />,
              children: [
                {
                  index: true,
                  element: (
                    <Navigate to={"/candidate/dashboard/exams/home"} replace />
                  ),
                },
                {
                  path: "home",
                  element: <CandidateExamsHome />,
                },
                registerRoute("register"),
              ],
            },
          ],
        },
      ],
    },
    {
      path: "/company",
      children: [
        {
          index: true,
          element: <Navigate to={"/company/dashboard"} replace />,
        },
        {
          path: "dashboard",
          element: <CompanyDashboard />,
          children: [
            {
              index: true,
              element: (
                <Navigate to={"/company/dashboard/delegations"} replace />
              ),
            },
            {
              path: "delegations",
              element: <CompanyDelegations />,
            },

            {
              path: "information",
              element: <CompanyInformation />,
            },
            {
              path: "candidates",
              element: <CompanyCandidates />,
            },
          ],
        },
        {
          path: "account-creation",
          element: <CompanyAccountCreation />,
        },
      ],
    },
    {
      path: "/exam",
      element: <Exam />,
      children: [
        {
          path: "login",
          element: <ExamLogin />,
        },
        {
          path: "",
          element: <ExamQuestions />,
        },
        {
          path: "done/:reason",
          element: <ExamDone />,
        },
      ],
    },
    {
      path: "/forgotten",
      children: [
        {
          path: "password",
          children: [
            {
              path: "ask",
              element: <ForgottenPassword />,
            },
            {
              path: "reset",
              element: <PasswordChange />,
            },
          ],
        },
        {
          path: "email",
          children: [
            {
              path: "ask",
              element: <ForgottenEmail />,
            },
          ],
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  if (["staging", "development"].includes(import.meta.env.VITE_MODE)) {
    tree.push({
      path: "/",
      element: <Welcome />,
    });

    tree.push({
      path: "/seed-data",
      element: <SeedData />,
    });
  }

  return tree;
};
